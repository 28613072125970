import React from "react";
import { Link } from "react-router-dom";
import { io } from "socket.io-client";
import Store from "store";
import Axios from "axios";
import {
    createCargoItem, deleteCargoItem,
    fetchItemsFromArr,
    isAdmin,
    assignTransportToItem,
    getTransportCompanies,
    getTransportCompany,
    getInspector, moveCargoItem,
    moveCargoItems, deleteOrder, renderSwalContent, getCargoItem, validateDuplicateVin, isClient, isManager, getInspectusComments, renderImportItemsErrorSwalContent
} from "../../../../utils/utils";
import {Accordion, Card, Dropdown, Form} from "react-bootstrap";
import ReactPaginate from "react-paginate";

import CargoItem from "./CargoItem/CargoItem";
import Filters from "./Filters/Filters";
import HelperControls from "./HelperControls/HelperControls";
import moment from "moment";

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import ConditionalContent from "../../../../ConditionalContent";
import Gallery from "../../../../Gallery/Gallery";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronLeft, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { vinValidation } from "../../../../utils/VinValidation";
import { parseCargo } from "../../../../utils/parseCago";
import InspectionController from "../../../../components/InspectionController/InspectionController";
import TransportController from "../../../../components/TransportController/TransportController";
import CloneOrder from "./CloneOrder/CloneOrder";
import { saveAs } from 'file-saver';
import ReactPlaceholder from "react-placeholder";
import PVReportController from "../../../../components/PVReportController/PVReportController";
import DatePicker from "react-datepicker";
import Style from "../../AllUsers/allUsers.module.scss";
import OrderStyle from './SingleOrder.module.scss';
import Echo from "laravel-echo";
import axios from "axios";

const ImportItemsModalSwal = withReactContent(Swal);
const ImportErrorModalSwal = withReactContent(Swal);
const LoadingModalSwal = withReactContent(Swal);
const ImportDamagesModalSwal = withReactContent(Swal);
const BulkActionsModal = withReactContent(Swal);
const reportModal = withReactContent(Swal);
const orderDeleteModal = withReactContent(Swal);
const transportChangeModal = withReactContent(Swal);
const addTransportModal = withReactContent(Swal);
const cloneOrderModal = withReactContent(Swal);


class SingleOrder extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            id: this.props.match.params.entryId,
            token: Store.get('accessToken').token,
            role: Store.get('accessToken').role,
            data: null,
            inspectors: [],
            inspectorNames: null,
            items: [],
            unallocatedItems: [],
            groupedItems: {},
            transports: null,
            order: null,
            orderby: null,
            countries: null,
            bls: null,
            voyages: null,
            makes: null,
            models: null,
            itemsCount: 0,
            currentCount: 0,
            perPage: 15,
            currentPage: 0,
            totalPages: 0,
            damageSetData: [],
            transportIds: null,
            images: [],
            itemsWithComments: null,
            commentCount: null,
            numberOfDamages: 0,
            itemCount: null,
            selectedCargo: [],
            selectAllChecked: false,
            selectedOrder: null,
            unallocatedErr: [],
            filtersOpen: false,
            itemsLoaded: false,
            cargoItemsLoaded: true,
            vinSearchInputIsDisabled: false,
            selectedTransportMode: null,
            availableTransportModes: [],
            allUsers: [],
            vinFilterValue: '',
            selectedChangeTransport: '',
            inspectusComments: [],
            transportType: '',
            withDifference: false,
            importedItems: [],
            importedDamages: [],
            previewImport: false,
            importedItemsInput: [],
            orderTransportCompany: null,
            showPVReportSettings: false,
            bulkShowCargoItems: false,
            pvrTransport: '',
            pvrDeliveryNote: '',
            pvrSendingCompany: '',
            pvrSendingTransport: '',
            pvrSendingName: false,
            pvrSendingSignature: false,
            pvrReceivingCompany: "",
            pvrReceivingTransport: '',
            pvrReceivingName: false,
            pvrReceivingSignature: false,
            transshipmentPorts: [],
            portsOfDischarging: [],
            pvrTime: "",
            pvrFrom: {
                "factory": true,
                "road": true,
                "rail": true,
                "sea": true,
                "barge": true,
                "compound": true,
                "pdi": true,
                "dealer": true 
            },
            pvrFromFactory: false,
            pvrFromRoad: false,
            pvrFromRail: false,
            pvrFromSea: false,
            pvrFromBarge: false,
            pvrFromCompound: false,
            pvrFromPdi: false,
            pvrFromDealer: false,
            pvrToFactory: false,
            pvrToRoad: false,
            pvrToRail: false,
            pvrToSea: false,
            pvrToBarge: false,
            pvrToCompound: false,
            pvrToPdi: false,
            pvrToDealer: false,
            pvrLocation: "text",
            pvrDate: '',
            pvrPlaceOfIssue: '',
            pvrTransportLoading: false,
            pvrTransportUnloading: false,
            pvrLocationFactory: false,
            pvrLocationCompound: false,
            pvrLocationDealer: false,
            pvrRailControlProductionFactory: false,
            pvrRailControlTransport: false,
            pvrRailControlLoading: false,
            pvrRailControlUnloading: false,
            importedItemsCounter: 0,
            numberOfItems: 0,
            importInProgress: false,
            importedPercentage: 0,
            filterData: {}
        };

        this.transportController = React.createRef();
        this.inspectionController = React.createRef();
        this.pvReportController = React.createRef();
        this.fetchCargoItems = this.fetchCargoItems.bind(this);
        this.renderItems = this.renderItems.bind(this);
        this.orderItems = this.orderItems.bind(this);
        this.importItemsModal = this.importItemsModal.bind(this);
        this.importItemsDifferenceModal = this.importItemsDifferenceModal.bind(this);
        this.importDamagesModal = this.importDamagesModal.bind(this);
        this.importItems = this.importItems.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.selectCargo = this.selectCargo.bind(this);
        this.unselectCargo = this.unselectCargo.bind(this);
        this.selectAllCargo = this.selectAllCargo.bind(this);
        this.unselectAllCargo = this.unselectAllCargo.bind(this);
        this.bulkActionControl = this.bulkActionControl.bind(this);
        this.moveItems = this.moveItems.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.deleteOrder = this.deleteOrder.bind(this);
        this.constructUnallocatedErr = this.constructUnallocatedErr.bind(this);
        this.fetchErrItems = this.fetchErrItems.bind(this);
        this.updateOrder = this.updateOrder.bind(this);
        this.handleScrollToElement = this.handleScrollToElement.bind(this);
        this.markInspected = this.markInspected.bind(this);
        this.toggleFilters = this.toggleFilters.bind(this);
        this.downloadPackage = this.downloadPackage.bind(this);
        this.fetchAllOrders = this.fetchAllOrders.bind(this);
        this.vinChange = this.vinChange.bind(this);
        this.handleTransportChange = this.handleTransportChange.bind(this);
        this.transportModal = this.transportModal.bind(this);
        this.moveItemToTransportMode = this.moveItemToTransportMode.bind(this);
        this.addTransportMode = this.addTransportMode.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.showAddTransportModal = this.showAddTransportModal.bind(this);
        this.fetchTransportModes = this.fetchTransportModes.bind(this);
        this.exportOrder = this.exportOrder.bind(this);
        this.exportPVReport = this.exportPVReport.bind(this);
        this.confirmImport = this.confirmImport.bind(this);
        this.noItems = this.noItems.bind(this);
        this.radioSelected = this.radioSelected.bind(this);
        this.createPVReportRequest = this.createPVReportRequest.bind(this);
        this.canEditOrder = this.canEditOrder.bind(this);
        this.downloadTemplate = this.downloadTemplate.bind(this);
        this.neptuneExport = this.neptuneExport.bind(this);
        this.cloneOrder = this.cloneOrder.bind(this);
        this.updateProgress = this.updateProgress.bind(this);
        this.rescanItems = this.rescanItems.bind(this);
        
        
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // document.getElementById("c-item-7")?.scrollIntoView();

        if (this.state.id !== this.props.match.params.entryId) {
            this.setState({
                id: this.props.match.params.entryId
            }, () => {
                this.updateOrder();
            });
        }

    }

    radioSelected(e) {
        let selection = e.target.value;
        if(selection === 'true') {
            this.setState({
                [e.target.name]: true
            })
        } else {
            this.setState({
                [e.target.name]: false
            })
        }
    }

    downloadPackage() {

        let config = {
            responseType: "blob"
        };
        config.headers = {
            'Authorization': 'Bearer ' + Store.get('accessToken').token
        };

        Axios.get(process.env.REACT_APP_API_BASE + '/orders/' + this.state.id + '/imagesarchive', config)
            .then(res => res.data)
            .then(blob => saveAs(blob, `order-${this.state.id}-images`))
    }

    updateOrder() {
        let config = {};
        config.headers = {
            'Authorization': 'Bearer ' + Store.get('accessToken').token
        };

        Axios.get(process.env.REACT_APP_API_BASE + '/orders/' + this.state.id + '/app', config).then(response => {
            console.log(response);

            Axios.get(process.env.REACT_APP_API_BASE + '/users', {
                headers: {
                    'Authorization': 'Bearer ' + this.state.token
                },
            } ).then(response => {
                if (response.status === 200) {
                    this.setState({
                        allUsers: response.data
                    })
                }
            }).catch(error => {
                console.log('error', error);
            });

            if (response.status === 200) {
                console.log(response);
                fetchItemsFromArr(response.data.order.inspectors, getInspector)
                    .then(items => {
                        console.log('count');
                        console.log(response.data.order)
                        this.setState({
                            inspectorsArr: response.data.order.inspectors,
                            itemsWithComments: response.data.items_with_comments,
                            commentCount: response.data.number_of_comments,
                            itemCount: response.data.number_of_items,
                            numberOfDamages: response.data.order.all_damages
                        });
                        return items?.map(i => {
                            return i.name;
                        })
                    })
                    .then(i => {
                        this.setState({
                            inspectorNames: i
                        })
                    });

                let smt = response.data.order.transports?.map(i => {
                    return i.id;
                });

                this.setState({
                    transportIds: smt,
                });

                console.log('transporttype: ');
                console.log(response.data.order)
                this.transportType = response.data.order.transportType;
                this.setState({
                    data: response.data.order,
                    transports: response.data.transports ? Object.values(response.data.transports) : null,
                    countries: response.data.countries ? Object.values(response.data.countries) : null,
                    bls: response.data.bls ? Object.values(response.data.bls) : null,
                    transshipmentPorts: response.data.transshipmentPorts ? Object.values(response.data.transshipmentPorts) : null,
                    portsOfDischarging: response.data.portsOfDischarging ? Object.values(response.data.portsOfDischarging) : null,
                    portsOfOrigin: response.data.portsOfOrigin ? Object.values(response.data.portsOfOrigin) : null,
                    voyages: response.data.voyages ? Object.values(response.data.voyages) : null,
                    transportCompanies: response.data.transportCompanies ? Object.values(response.data.transportCompanies) : null,
                    models: response.data.models ? Object.values(response.data.models) : null,
                    makes: response.data.makes ? Object.values(response.data.makes) : null,
                    damageSetData: response.data.order.damageSet ? response.data.order.damageSet.damage_set_options : [],
                    images: response.data.order.images ? response.data.order.images : [],
                    damageSet: response.data.order.damageSet?.id || null,
                    transportType: response.data.order.transportType
                });
            } else {
                throw new Error('Smt went wrong with fetching orders');
            }
        })
            .then(() => {
                return this.fetchCargoItems();
            })
            .catch(err => {
                console.log(err);
            });



    }

    handleScrollToElement() {
        let element = document.getElementById("cargo-results");
        const yOffset = -200;
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
    }

    updateProgress(data) {
        console.log(data)
    }

    componentDidMount() {
        const socket = io(process.env.REACT_APP_SOCKET);
        socket.on( 'connect', function() {
            console.log('socket dela!!!!');
        });

        let socketChannel = "import." + this.state.id
        console.log(socketChannel);

        socket.on(socketChannel,data => this.setState({
            importedItemsCounter: data.importedItemsCounter,
            importedPercentage: (data.importedItemsCounter * 100) / this.state.numberOfItems
        }));
        
        this.fetchTransportModes();
        

    }

    fetchTransportModes() {
        Axios.get(process.env.REACT_APP_API_BASE + '/transportmode', {
            params: {
                order_id: this.state.id
            },
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            }
        })
            .then(res => {
                if (res.status === 200) {
                    this.setState({
                        availableTransportModes: res.data
                    })
                }
            })
        this.updateOrder();
    }

    fetchCargoItems(data, page, vin) {
        this.setState({
            filterData: data
        })
        let config = {};

        this.unselectAllCargo();

        if (!this.state.id) return;

        config.headers = {
            'Authorization': 'Bearer ' + this.state.token
        };

        config.params = {
            'order_id': this.state.id
        };

        if (data) {
            const {
                transportCompany,
                transport,
                vin,
                info,
                portOfOrigin,
                transshipmentPort,
                portOfDischarging,
                destination,
                bl,
                voyage,
                fromDate,
                toDate,
                make,
                model,
                clientComment,
                inspectusComment,
                inspectedBy,
                transportMode,
                damageSetPart,
                damageSetSeverity,
                damageSetType,
                damageSetCause,
                damageSetRepairMode,
                damageSetClass,
                damageSetDln,

                inspected,
                uninspected,
                damaged,
                undamaged,
                images,
                reports,
                perPage

            } = data;

            if (transportCompany) {
                config.params['transportCompany'] = transportCompany?.map(i => i.value);
            }
            if (transport) {
                config.params['transport_id'] = transport?.map(i => i.value);
            }
            if (this.state.vinFilterValue) {
                config.params['vin'] = this.state.vinFilterValue
            } else {
                if (vin) {
                    config.params['vin'] = vin;
                }
            }

            if (info) {
                config.params['info'] = info;
            }

            if (inspected) {
                config.params['inspected'] = true;
            }

            if (uninspected) {
                config.params['uninspected'] = true;
            }

            if (portOfOrigin) {
                config.params['portOfOrigin'] = portOfOrigin?.map(i => i.value);
            }
            if (transshipmentPort) {
                config.params['transshipmentPort'] = transshipmentPort?.map(i => i.value);
            }
            if (portOfDischarging) {
                config.params['portOfDischarging'] = portOfDischarging?.map(i => i.value);
            }
            if (destination) {
                config.params['destination'] = destination?.map(i => i.value);
            }
            if (bl) {
                config.params['bl'] = bl?.map(i => i.value);
            }
            if (voyage) {
                config.params['voyage'] = voyage?.map(i => i.value);
            }
            if (make) {
                config.params['make'] = make?.map(i => i.value);
            }
            if (model) {
                config.params['model'] = model?.map(i => i.value);
            }

            if (transportMode) {
                config.params.transportModeId= transportMode?.map(i=>i.value);
            }

            if (clientComment) {
                config.params['clientComment'] = clientComment;
            }

            if (inspectusComment) {
                config.params['inspectusComment'] = inspectusComment;
            }

            if (inspectedBy) {
                config.params['inspectedBy'] = inspectedBy?.map(i => i.value);
            }

            if (inspectusComment) {
                config.params['inspectusComment'] = inspectusComment?.map(i => i.value);
            }

            if (damageSetPart) {
                config.params['damageSetPart'] = damageSetPart?.map(i => i.value);
            }
            if (damageSetSeverity) {
                config.params['damageSetSeverity'] = damageSetSeverity?.map(i => i.value);
            }
            if (damageSetType) {
                config.params['damageSetType'] = damageSetType?.map(i => i.value);
            }
            if (damageSetCause) {
                config.params['damageSetCause'] = damageSetCause?.map(i => i.value);
            }
            if (damageSetRepairMode) {
                config.params['damageSetRepairMode'] = damageSetRepairMode?.map(i => i.value);
            }
            if (damageSetClass) {
                config.params['damageSetClass'] = damageSetClass?.map(i => i.value);
            }
            if (damageSetDln) {
                config.params['damageSetDln'] = damageSetDln?.map(i => i.value);
            }


            if (inspected) {
                config.params['inspected'] = true;
            }
            console.log("DAMAGED!!!");
            console.log(damaged);

            if (damaged) {
                config.params['damaged'] = true;
            }

            if (undamaged) {
                config.params['undamaged'] = true;
            }
            if (images) {
                config.params['images'] = true;
            }
            if (reports) {
                config.params['reports'] = true;
            }

            if (perPage) {
                config.params['per_page'] = parseInt(perPage)
                this.setState({
                    perPage: parseInt(perPage),
                })
                
            }


            if (fromDate) {
                config.params['inspection_from'] = moment(fromDate).format('DD.MM.YYYY');
            }
            if (toDate) {
                config.params['inspection_to'] = moment(toDate).format('DD.MM.YYYY');
            }
        } else {
            if (this.state.vinFilterValue) {
                config.params.vin = this.state.vinFilterValue;
            }
        }

        //Sorting
        if (this.state.order) {
            config.params.order = this.state.order;
        }
        if (this.state.orderby) {
            config.params.orderby = this.state.orderby;
        }
        if (this.state.selectedTransportMode) {
            config.params.transportModeId = this.state.selectedTransportMode
        }

        if (page) {
            config.params.page = page;
        }

        return Axios.get(process.env.REACT_APP_API_BASE + '/items', config)
            .then(response => {

                if (response.status === 200) {

                    if (response.data.items) {
                        console.log('Per page');
                        console.log(this.state.perPage);
                        console.log(( this.state.perPage !== -1 ? 'It is not fucking -1' : 'it is fucking -1'));
                        this.setState({
                            items: response.data.items,
                            currentPage: ( this.state.perPage !== -1 ? response.data.paginationData['current_page'] : 0) ,
                            totalPages: (this.state.perPage !== -1 ? response.data.paginationData['total_pages'] : 0),
                            itemCount: (this.state.perPage !== -1 ? response.data.paginationData.total : this.state.itemCount)
                        });
                    }

                    if (response.data.items && response.data.items.length > 0) {


                        const damaged = response.data.items.filter(item => {
                            return item.damages.length > 0;
                        });

                        this.setState({
                            damagedCount: damaged.length
                        });
                    }
                    this.setState({ itemsLoaded: true });
                    this.setState({ cargoItemsLoaded: true });

                    if(this.state.transportIds.length > 0) {
                        Axios.get(process.env.REACT_APP_API_BASE + '/transports/'+this.state.transportIds[0], {
                            headers: {
                                'Authorization': 'Bearer ' + this.state.token
                            },
                        } ).then(resp => {
                            this.setState({
                                orderTransportCompany: resp.data.transport.identificationName
                            })
    
                            console.log(this.state.orderTransportCompany);
    
                        })
                    }
                    
                } else {
                    throw new Error('Smt went wrong with fetching orders');
                }
            })
            .catch(err => {
                console.log(err);
            });

            
    }


    selectCargo(item) {
        let itemId = parseInt(item);
        if (!this.state.selectedCargo.includes(itemId)) {
            this.setState({
                selectedCargo: [...this.state.selectedCargo, itemId]
            });
        }
    }

    unselectCargo(item) {

        let itemId = parseInt(item);

        if (this.state.selectedCargo.includes(itemId)) {
            this.setState({
                selectedCargo: this.state.selectedCargo.filter(item => item !== itemId)
            });
        }
    }

    selectAllCargo() {
        this.setState({
            selectAllChecked: true,
            selectedCargo: this.state.items?.map(item => item.id)
        })
    }

    unselectAllCargo() {
        this.setState({
            selectAllChecked: false,
            selectedCargo: []
        })
    }

    handleSelectChange(obj, e) {

        this.setState({
            [e.name]: obj
        })
    }

    selectOrderModal(items) {
        return (
            <div className={"select-order-modal-wrapper"}>
                <Select
                    closeMenuOnSelect={true}
                    options={items}
                    onChange={this.handleSelectChange}
                    name={"selectedOrder"}
                />
            </div>
        );
    }

    // If user is client and order is in phase 2 don't allow client to edit anything inside order 
    canEditOrder() {
        if(isClient() && this.state.data?.phase != 2) {
            return true
        } else if(isAdmin()) {
            return true;
        } else {
            return false;   
        }
    }


    bulkActionControl(action, dsgId = null) {
        if (this.state.selectedCargo && this.state.selectedCargo.length > 0) {
            if (action === 'delete') {
                BulkActionsModal.fire({
                    title: 'Are you sure you want to delete?',
                    showCancelButton: true,
                }).then(r => {
                    if (r.isConfirmed) {
                        this.deleteItems()
                            .then(() => {
                                this.unselectAllCargo();
                                BulkActionsModal.fire({
                                    title: 'Successfully deleted cargo items',
                                    icon: 'success'
                                }).then(() => {
                                    this.updateOrder();
                                });
                            }).catch(err => {
                                BulkActionsModal.fire({
                                    title: 'Something went wrong',
                                    icon: 'error'
                                }).then(() => {
                                    this.updateOrder();
                                });
                            });
                    }
                });
            } else if (action === 'move') {
                this.fetchAllOrders()
                    .then(r => {
                        return BulkActionsModal.fire({
                            title: 'Select order',
                            html: this.selectOrderModal(r),
                            showCancelButton: true,
                        })
                    })
                    .then(r => {
                        if (r.isConfirmed && this.state.selectedOrder) {
                            return this.moveItems(parseInt(this.state.selectedOrder.value));
                        } else {
                            this.setState({
                                selectedOrder: null
                            })
                            return false;
                        }
                    })
                    .then(r => {
                        if (r) {
                            this.updateOrder();
                            this.setState({
                                selectedOrder: null
                            })
                            return BulkActionsModal.fire({
                                title: 'Successfully moved cargo items',
                            });
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    });
            }
            else if (action === 'unallocate') {
                BulkActionsModal.fire({
                    title: 'Are you sure you want to set as unallocated',
                    showCancelButton: true,
                })
                    .then(r => {
                        if (r.isConfirmed) {
                            return this.moveItems(null);
                        } else {
                            this.setState({
                                selectedOrder: null
                            })
                            return false;
                        }
                    })
                    .catch(err => {
                        if (err) {
                            this.setState({
                                unallocatedErr: [...this.state.unallocatedErr, err]
                            });
                            return err;
                        }
                    })
                    .then(async r => {
                        this.fetchCargoItems();
                        this.setState({
                            selectedOrder: null
                        });

                        let swalObj = {};
                        if (r) {
                            if (this.state.unallocatedErr && this.state.unallocatedErr.length > 0) {
                                let itemsArr = await this.fetchErrItems();
                                swalObj = {
                                    title: 'Unable to set selected Cargo Item(s) as Unallocated ',
                                    html: renderSwalContent(itemsArr),
                                    icon: 'warning'
                                };
                            } else {
                                swalObj = {
                                    title: 'Selected Cargo Item(s) successfully set as Unallocated ',
                                    icon: 'success'
                                };
                            }

                            return BulkActionsModal.fire(swalObj);
                        }
                    })
                    .then(() => {
                        return this.setState({ unallocatedErr: [] });
                    });
            }
            else if (action === 'inspect') {
                this.inspectionController.current?.bulkInspect(this.state.selectedCargo, this.state.data.id);
            } else if (action === 'changeTransport') {
                let transportModesArr = [];

                for (let transportMode of this.state.availableTransportModes) {
                    let transportPlateLabel = this.state.data.transportType === 'train' ? transportMode.field_1 : transportMode.field_1 + '-' + transportMode.field_2;

                    transportModesArr.push({
                        'value': transportMode.id,
                        'label': transportPlateLabel
                    });
                }
                return transportChangeModal.fire({
                    title: 'Change transport',
                    html: this.transportModal(transportModesArr),
                    showLoaderOnConfirm: true
                }).then(r => {
                    if (r.isConfirmed) {
                        this.moveItemToTransportMode(this.state.selectedChangeTransport.value);
                        this.fetchCargoItems();
                        transportChangeModal.fire({
                            title: 'Successfully changed transport mode of selected items',
                            icon: 'success'
                        });

                    } else {
                        return false;
                    }
                });
            } else if (action === 'toggleItems') {
                this.setState({ 
                    bulkShowCargoItems: !this.state.bulkShowCargoItems 
                });
            } else if(action === 'deleteDamages') {
                BulkActionsModal.fire({
                    title: 'Are you sure you want to delete?',
                    showCancelButton: true,
                }).then(r => {
                    if (r.isConfirmed) {
                        Axios.post(process.env.REACT_APP_API_BASE + '/damages/order/bulkdelete', {items: this.state.selectedCargo, dsgId: dsgId}, {
                            headers: {
                                'Authorization': 'Bearer ' + this.state.token
                            }
                        })
                        .then(res => {
                            if(res.status === 200) {
                                transportChangeModal.fire({
                                    title: 'Successfully deleted items',
                                    icon: 'success'
                                });
                            }
                        })
                    }
                });
            }
        }
    }

    fetchErrItems() {
        let promises = [];

        for (let item of this.state.unallocatedErr) {
            promises.push(() => getCargoItem(item));
        }

        return Promise.all(promises.map(func => func())).then(items => {
            return items.map(i => i.vin);
        });
    }

    constructUnallocatedErr(items) {
        return items?.map(item => <li>{item}</li>);
    }

    moveItems(orderId) {
        let promises = [];

        for (let item of this.state.selectedCargo) {
            promises.push(() => moveCargoItem(item, orderId, this.state.id));
        }

        return Promise.all(promises.map(func => func()));
    }

    fetchAllOrders() {

        return new Promise((resolve, reject) => {
            let config = {};

            config.headers = {
                'Authorization': 'Bearer ' + this.state.token
            };
            config.params = {
                damage_set: this.state.damageSet
            };

            Axios.get(process.env.REACT_APP_API_BASE + '/orders', config)
                .then(response => {
                    if (response.status === 200) {
                        let ordersArr = [];
                        let orders = response.data.orders.filter(i => i.id !== parseInt(this.state.id));

                        for (let order of orders) {
                            ordersArr.push({
                                'value': order.id,
                                'label': order.idNumber
                            });
                        }

                        resolve(ordersArr);
                    } else {
                        reject('Something went wrong');
                    }
                });
        });
    }

    //Trigger delete on all simultaniously
    deleteItems() {
        let promises = [];

        for (let item of this.state.selectedCargo) {
            promises.push(() => deleteCargoItem(item, this.state.id));
        }

        return Promise.all(promises.map(func => func()));
    }

    moveItemToTransportMode(transportId) {
        let promises = [];

        promises.push(() => assignTransportToItem(this.state.selectedCargo, transportId))

        return Promise.all(promises.map(func => func()));
    }

    handleTransportChange(e) {
        console.log(e.target.value);
        this.setState({ cargoItemsLoaded: false });
        this.setState({
            selectedTransportMode: [parseInt(e.target.value)]
        })
        this.updateOrder();
    }

    // It's not clean code but it's working
    renderPVReportSettings() {
        console.log('CLICKED PV REPORT: ' + this.state.data.damageSet.name);
        return (
            <>
            <div className={"text-start"}>
                    <ConditionalContent check={ this.state.data.damageSet.name === 'Calibro' || this.state.data.damageSet.name === 'Flas Aves' || this.state.data.damageSet.name === 'Inspectus' || this.state.data.damageSet.name === 'AIAG' || this.state.data.damageSet.name === 'RUA' }>
                        <div class="row mb-2">
                            <h4>Sending party</h4>
                            <div className="col-12 col-md-6">
                                <label htmlFor="pvrSendingCompany" className={"mb-1"}>Company</label>
                                <input type="text" name="pvrSendingCompany" id={"pvrSendingCompany"} className="vinSearch form-control w-100" itemID="pvrSendingCompany" value={this.state.data.pvrSendingCompany} onChange={this.handleChange}></input>
                            </div>
                            <div className="col-12 col-md-6">
                                <label htmlFor="pvrSendingTransport" className={"mb-1"}>Transport</label>
                                <input type="text" name="pvrSendingTransport" id={"pvrSendingTransport"} className="vinSearch form-control w-100" itemID="pvrSendingTransport" value={this.state.pvrSendingTransport} onChange={this.handleChange}></input>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div className="col-12 col-md-6">
                                <div className="form-group">
                                    <h6>Name</h6>
                                    <div className="form-check">
                                        <input checked={this.state.pvrSendingName === true} value="true" className="form-check-input" type="radio" name="pvrSendingName" id="pvrSendingName" onChange={this.radioSelected}/>Yes
                                    </div>
                                    <div className="form-check">
                                        <input checked={this.state.pvrSendingName === false} value="false" className="form-check-input" type="radio" name="pvrSendingName" id="pvrSendingName" onChange={this.radioSelected}/>No
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="form-group">
                                    <h6>Signature</h6>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="true" checked={this.state.pvrSendingSignature === true} className="form-check-input" type="radio" name="pvrSendingSignature" id="flexRadioDefault1" />Yes
                                    </div>
                                    <div className="form-check">
                                        <input onChange={this.radioSelected} value="false" checked={this.state.pvrSendingSignature === false} className="form-check-input" type="radio" name="pvrSendingSignature" id="flexRadioDefault2" />No
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <h4>Receiving party</h4>
                            <div className="col-12 col-md-6">
                                <label htmlFor="pvrReceivingCompany" className={"mb-1"}>Company</label>
                                <input type="text" name="pvrReceivingCompany" id={"pvrReceivingCompany"} className="vinSearch form-control w-100" itemID="pvrReceivingCompany" value={this.state.pvrReceivingCompany} onChange={this.handleChange} required></input>
                            </div>
                            <div className="col-12 col-md-6">
                                <label htmlFor="pvrReceivingTransport" className={"mb-1"}>Transport</label>
                                <input type="text" name="pvrReceivingTransport" id={"pvrReceivingTransport"} className="vinSearch form-control w-100" itemID="pvrReceivingTransport" value={this.state.pvrReceivingTransport} onChange={this.handleChange} required></input>
                            </div>
                        </div>
                        <div class="row">
                            <div className="col-12 col-md-6">
                                <div className="form-group">
                                    <h6>Name</h6>
                                    <div className="form-check">
                                        <input name="pvrReceivingName" checked={this.state.pvrReceivingName === true} id="pvrReceivingName" className="form-check-input" type="radio" value="true" onChange={this.radioSelected}/>Yes
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={this.state.pvrReceivingName === false} type="radio" name="pvrReceivingName" id="pvrReceivingName" value="false" onChange={this.radioSelected}/>No
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="form-group">
                                    <h6>Signature</h6>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={this.state.pvrReceivingSignature === true} type="radio" name="pvrReceivingSignature" id="flexRadioDefault1" value="true" onChange={this.radioSelected}/>Yes
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={this.state.pvrReceivingSignature === false} type="radio" name="pvrReceivingSignature" id="flexRadioDefault2" value="false" onChange={this.radioSelected}/>No
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ConditionalContent>

                    <ConditionalContent check={ this.state.data.damageSet.name === 'RUA'}>
                        <div class="row mt-2">
                            <div className="col-12 col-md-4">
                                <div className="form-group">
                                    <label htmlFor="arrival-date">Date</label>
                                    <DatePicker className={'form-control'} dateFormat="dd.MM.yyyy"
                                        selected={this.state.pvrDate}
                                        utcOffset={0}
                                        onChange={(date) => this.setPVRdate(date)} />
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="form-group">
                                        <div className="form-check">
                                            <label htmlFor="pvrLocation" className={"mb-1"}>Location</label>
                                            <input type="text" name="pvrLocation" id={"pvrLocation"} className="vinSearch form-control w-100" itemID="pvrLocation" value={this.state.data.pvrLocation} onChange={this.handleChange}></input>
                                        </div>
                                </div>
                            </div>

                            <div className="col-12 col-md-4">
                                <div className="form-group">
                                    <div className="form-check">
                                        <label htmlFor="pvrTransport" className={"mb-1"}>Transport</label>
                                        <input type="text" name="pvrTransport" id={"pvrTransport"} className="vinSearch form-control w-100" itemID="pvrTransport" value={this.state.data.pvrTransport} onChange={this.handleChange}></input>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div className="col-12 col-md-4">
                                <div className="form-group">
                                    <div className="">
                                        <label htmlFor="pvrDeliveryNote" className={"mb-1"}>Delivery note</label>
                                        <input type="text" name="pvrDeliveryNote" id={"pvrDeliveryNote"} className="vinSearch form-control w-100" itemID="pvrDeliveryNote" value={this.state.data.pvrDeliveryNote} onChange={this.handleChange}></input>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </ConditionalContent>

                    <ConditionalContent check={ this.state.data.damageSet.name === 'RUA' }>
                        <div class="row mb-2 mt-4">
                            <div className="col-12 col-md-2">
                                <div className="form-group">
                                    <h6>Transport loading</h6>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={this.state.pvrTransportLoading === true} type="radio" name="pvrTransportLoading" id="flexRadioDefault1" value="true" onChange={this.radioSelected}/>Yes
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={this.state.pvrTransportLoading === false} type="radio" name="pvrTransportLoading" id="flexRadioDefault2" value="false" onChange={this.radioSelected}/>No
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-2">
                                <div className="form-group">
                                    <h6>Transport unloading</h6>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={this.state.pvrTransportUnloading === true}  type="radio" name="pvrTransportUnloading" id="flexRadioDefault1" value="true" onChange={this.radioSelected}/>Yes
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input"  checked={this.state.pvrTransportUnloading === false} type="radio" name="pvrTransportUnloading" id="flexRadioDefault2" value="false" onChange={this.radioSelected}/>No
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-2">
                                <div className="form-group">
                                    <h6>Location factory</h6>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={this.state.pvrLocationFactory === true}  type="radio" name="pvrLocationFactory" id="flexRadioDefault1" value="true" onChange={this.radioSelected}/>Yes
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input"  checked={this.state.pvrLocationFactory === false} type="radio" name="pvrLocationFactory" id="flexRadioDefault2" value="false" onChange={this.radioSelected}/>No
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-2">
                                <div className="form-group">
                                    <h6>Location compound</h6>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={this.state.pvrLocationCompound === true}  type="radio" name="pvrLocationCompound" id="flexRadioDefault1" value="true" onChange={this.radioSelected}/>Yes
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input"  checked={this.state.pvrLocationCompound === false} type="radio" name="pvrLocationCompound" id="flexRadioDefault2" value="false" onChange={this.radioSelected}/>No
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-2">
                                <div className="form-group">
                                    <h6>Location dealer</h6>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={this.state.pvrLocationDealer === true}  type="radio" name="pvrLocationDealer" id="flexRadioDefault1" value="true" onChange={this.radioSelected}/>Yes
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input"  checked={this.state.pvrLocationDealer === false} type="radio" name="pvrLocationDealer" id="flexRadioDefault2" value="false" onChange={this.radioSelected}/>No
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-2">
                                <div className="form-group">
                                    <h6>Rail control production factory</h6>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={this.state.pvrRailControlProductionFactory === true}  type="radio" name="pvrRailControlProductionFactory" id="flexRadioDefault1" value="true" onChange={this.radioSelected}/>Yes
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input"  checked={this.state.pvrRailControlProductionFactory === false} type="radio" name="pvrRailControlProductionFactory" id="flexRadioDefault2" value="false" onChange={this.radioSelected}/>No
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-2">
                                <div className="form-group">
                                    <h6>Rail control transport</h6>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={this.state.pvrRailControlTransport === true}  type="radio" name="pvrRailControlTransport" id="flexRadioDefault1" value="true" onChange={this.radioSelected}/>Yes
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input"  checked={this.state.pvrRailControlTransport === false} type="radio" name="pvrRailControlTransport" id="flexRadioDefault2" value="false" onChange={this.radioSelected}/>No
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-2">
                                <div className="form-group">
                                    <h6>Rail control loading</h6>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={this.state.pvrRailControlLoading === true}  type="radio" name="pvrRailControlLoading" id="flexRadioDefault1" value="true" onChange={this.radioSelected}/>Yes
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input"  checked={this.state.pvrRailControlLoading === false} type="radio" name="pvrRailControlLoading" id="flexRadioDefault2" value="false" onChange={this.radioSelected}/>No
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-2">
                                <div className="form-group">
                                    <h6>Rail control unloading</h6>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={this.state.pvrRailControlUnloading === true}  type="radio" name="pvrRailControlUnloading" id="flexRadioDefault1" value="true" onChange={this.radioSelected}/>Yes
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input"  checked={this.state.pvrRailControlUnloading === false} type="radio" name="pvrRailControlUnloading" id="flexRadioDefault2" value="false" onChange={this.radioSelected}/>No
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ConditionalContent>



                    <ConditionalContent check={ this.state.data.damageSet.name === 'Flas Aves'}>

                        <div class="row mt-2">
                            <div className="col-12 col-md-2">
                                <div className="form-group">
                                    <label htmlFor="arrival-date">Place of Issue</label>
                                    <input type="text" name="placeOfIssue" id={"placeOfIssue"} className="vinSearch form-control w-100" itemID="placeOfIssue" value={this.state.placeOfIssue} onChange={this.handleChange} required></input>
                                </div>
                            </div>
                        </div>
                    </ConditionalContent>

                    <ConditionalContent check={ this.state.data.damageSet.name === 'Calibro' || this.state.data.damageSet.name === 'Flas Aves' || this.state.data.damageSet.name === 'Inspectus' || this.state.data.damageSet.name === 'AIAG'}>
                        <div class="row mt-2">
                            <div className="col-12 col-md-2">
                                <div className="form-group">
                                    <label htmlFor="arrival-date">Date</label>
                                    <DatePicker className={'form-control'} dateFormat="dd.MM.yyyy"
                                        selected={this.state.pvrDate}
                                        utcOffset={0}
                                        onChange={(date) => this.setPVRdate(date)} />
                                </div>
                            </div>
                        </div>
                    </ConditionalContent> 

                    <ConditionalContent check={ this.state.data.damageSet.name === 'Inspectus' }>
                            <div className="col-12 col-md-2">
                                <div className="form-group">
                                    <div className="">
                                        <label htmlFor="pvrLocation" className={"mb-1"}>Location</label>
                                        <input type="text" name="pvrLocation" id={"pvrLocation"} className="vinSearch form-control w-100" itemID="pvrLocation" value={this.state.data.pvrLocation} onChange={this.handleChange}></input>
                                    </div>
                                </div>
                            </div>
                        </ConditionalContent>

                    <ConditionalContent check={ this.state.data.damageSet.name === 'Inspectus' }>
                        <div class="row mb-2">
                            <h4>From</h4>
                            <div className="col-12 col-md-2">
                                <div className="form-group">
                                    <h6>Factory</h6>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={this.state.pvrFromFactory === true} type="radio" name="pvrFromFactory" id="flexRadioDefault1" value="true" onChange={this.radioSelected}/>Yes
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={this.state.pvrFromFactory === false} type="radio" name="pvrFromFactory" id="flexRadioDefault2" value="false" onChange={this.radioSelected}/>No
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-2">
                                <div className="form-group">
                                    <h6>Road</h6>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={this.state.pvrFromRoad === true}  type="radio" name="pvrFromRoad" id="flexRadioDefault1" value="true" onChange={this.radioSelected}/>Yes
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input"  checked={this.state.pvrFromRoad === false} type="radio" name="pvrFromRoad" id="flexRadioDefault2" value="false" onChange={this.radioSelected}/>No
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-2">
                                <div className="form-group">
                                    <h6>Rail</h6>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={this.state.pvrFromRail === true} type="radio" name="pvrFromRail" id="flexRadioDefault1" value="true" onChange={this.radioSelected}/>Yes
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={this.state.pvrFromRail === false} type="radio" name="pvrFromRail" id="flexRadioDefault2" value="false" onChange={this.radioSelected}/>No
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-2">
                                <div className="form-group">
                                    <h6>Sea</h6>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={this.state.pvrFromSea === true} type="radio" name="pvrFromSea" id="flexRadioDefault1" value="true" onChange={this.radioSelected}/>Yes
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={this.state.pvrFromSea === false} type="radio" name="pvrFromSea" id="flexRadioDefault2" value="false" onChange={this.radioSelected}/>No
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-2">
                                <div className="form-group">
                                    <h6>Barge</h6>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={this.state.pvrFromBarge === true} type="radio" name="pvrFromBarge" id="flexRadioDefault1" value="true" onChange={this.radioSelected}/>Yes
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={this.state.pvrFromBarge === false} type="radio" name="pvrFromBarge" id="flexRadioDefault2" value="false" onChange={this.radioSelected}/>No
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-2 mt-2">
                                <div className="form-group">
                                    <h6>Compound</h6>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={this.state.pvrFromCompound === true}  type="radio" name="pvrFromCompound" id="flexRadioDefault1" value="true" onChange={this.radioSelected}/>Yes
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input"  checked={this.state.pvrFromCompound === false} type="radio" name="pvrFromCompound" id="flexRadioDefault2" value="false" onChange={this.radioSelected}/>No
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-2 mt-2">
                                <div className="form-group">
                                    <h6>pdi</h6>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={this.state.pvrFromPdi === true} type="radio" name="pvrFromPdi" id="flexRadioDefault1" value="true" onChange={this.radioSelected}/>Yes
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={this.state.pvrFromPdi === false} type="radio" name="pvrFromPdi" id="flexRadioDefault2" value="false" onChange={this.radioSelected}/>No
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-2 mt-2">
                                <div className="form-group">
                                    <h6>Dealer</h6>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={this.state.pvrFromDealer === true} type="radio" name="pvrFromDealer" id="flexRadioDefault1" value="true" onChange={this.radioSelected}/>Yes
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={this.state.pvrFromDealer === false} type="radio" name="pvrFromDealer" id="flexRadioDefault2" value="false" onChange={this.radioSelected}/>No
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ConditionalContent>

                    <ConditionalContent check={ this.state.data.damageSet.name === 'Inspectus' }>
                        <div class="row mb-2">
                            <h4>To</h4>
                            <div className="col-12 col-md-2">
                                <div className="form-group">
                                    <h6>Factory</h6>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={this.state.pvrToFactory === true} type="radio" name="pvrToFactory" id="flexRadioDefault1" value="true" onChange={this.radioSelected}/>Yes
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={this.state.pvrToFactory === false} type="radio" name="pvrToFactory" id="flexRadioDefault2" value="false" onChange={this.radioSelected}/>No
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-2">
                                <div className="form-group">
                                    <h6>Road</h6>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={this.state.pvrToRoad === true} type="radio" name="pvrToRoad" id="flexRadioDefault1" value="true" onChange={this.radioSelected}/>Yes
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={this.state.pvrToRoad === false} type="radio" name="pvrToRoad" id="flexRadioDefault2" value="false" onChange={this.radioSelected}/>No
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-2">
                                <div className="form-group">
                                    <h6>Rail</h6>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={this.state.pvrToRail === true} type="radio" name="pvrToRail" id="flexRadioDefault1" value="true" onChange={this.radioSelected}/>Yes
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={this.state.pvrToRail === false} type="radio" name="pvrToRail" id="flexRadioDefault2" value="false" onChange={this.radioSelected}/>No
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-2">
                                <div className="form-group">
                                    <h6>Sea</h6>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={this.state.pvrToSea === true} type="radio" name="pvrToSea" id="flexRadioDefault1" value="true" onChange={this.radioSelected}/>Yes
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={this.state.pvrToSea === false} type="radio" name="pvrToSea" id="flexRadioDefault2" value="false" onChange={this.radioSelected}/>No
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-2">
                                <div className="form-group">
                                    <h6>Barge</h6>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={this.state.pvrToBarge === true} type="radio" name="pvrToBarge" id="flexRadioDefault1" value="true" onChange={this.radioSelected}/>Yes
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={this.state.pvrToBarge === false} type="radio" name="pvrToBarge" id="flexRadioDefault2" value="false" onChange={this.radioSelected}/>No
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-2 mt-2">
                                <div className="form-group">
                                    <h6>Compound</h6>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={this.state.pvrToCompound === true} type="radio" name="pvrToCompound" id="flexRadioDefault1" value="true" onChange={this.radioSelected}/>Yes
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={this.state.pvrToCompound === false} type="radio" name="pvrToCompound" id="flexRadioDefault2" value="false" onChange={this.radioSelected}/>No
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-2 mt-2">
                                <div className="form-group">
                                    <h6>pdi</h6>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={this.state.pvrToPdi === true} type="radio" name="pvrToPdi" id="flexRadioDefault1" value="true" onChange={this.radioSelected}/>Yes
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={this.state.pvrToPdi === false} type="radio" name="pvrToPdi" id="flexRadioDefault2" value="false" onChange={this.radioSelected}/>No
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-2 mt-2">
                                <div className="form-group">
                                    <h6>Dealer</h6>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={this.state.pvrToDealer === true} type="radio" name="pvrToDealer" id="flexRadioDefault1" value="true" onChange={this.radioSelected}/>Yes
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" checked={this.state.pvrToDealer === false} type="radio" name="pvrToDealer" id="flexRadioDefault2" value="false" onChange={this.radioSelected}/>No
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ConditionalContent>

                    <ConditionalContent check={this.state.data.damageSet.name === 'Flas Aves' || this.state.data.damageSet.name === 'Inspectus' || this.state.data.damageSet.name === 'AIAG'}>
                        <div class="row mt-2">
                            <div className="col-12 col-md-2">
                                <label htmlFor="pvrTime" className={"mb-1"}>Time</label>
                                <input type="text" name="pvrTime" id={"pvrTime"} placeholder="14:00" className="vinSearch form-control w-100" value={this.state.pvrTime} onChange={this.handleChange}></input>
                            </div>
                        </div>
                    </ConditionalContent>             

                </div>

                <div class="row">
                    <div className="col-12 col-md-2 mt-4">
                        <button className="btn btn-primary" onClick={() => this.acceptPVReportCreation()}>Accept</button>
                    </div>


                </div>
            </>
        )
    }

    createPVReportRequest(data) {
        LoadingModalSwal.fire({
            title: 'Please wait. Creating report',
            showLoaderOnConfirm: false,
            showCancelButton: false,
            didOpen: () => {
                Swal.showLoading()
            }
        });
        Axios.post(process.env.REACT_APP_API_BASE + '/reports/' + this.state.id + '/pv', data, {
            headers: {
                'Authorization': 'Bearer ' + Store.get('accessToken').token,
                
            }, responseType: 'blob'
        })
        .then(res => res.data)
        .then(blob => {saveAs(blob, `order-${this.state.id}-pvreport`)})
        .then(response => {
            LoadingModalSwal.close();
        }) 
            .catch((error) => {
                if(error.response.status === 400) {
                    LoadingModalSwal.fire({
                        title: 'Please upload your signature',
                        showLoaderOnConfirm: false,
                        showCancelButton: false,
                    });
                } else if (error.response.status === 500) {
                    LoadingModalSwal.fire({
                        title: 'Something went wrong',
                        showLoaderOnConfirm: false,
                        showCancelButton: false,
                    });
                } else if (error.response.status === 401) {
                    LoadingModalSwal.fire({
                        title: 'No damages to export',
                        showLoaderOnConfirm: false,
                        showCancelButton: false,
                    });
                }
            });

    }

    acceptPVReportCreation() {
        if(this.state.data.damageSet.name === 'Calibro') {
            const requestObj = {
                "items": this.state.items.map(a => a.id),
                "sending_party": {
                    "company": this.state.pvrSendingCompany,
                    "transport": this.state.pvrSendingTransport,
                    "name": this.state.pvrSendingName,
                    "signature": this.state.pvrSendingSignature
                },
                "receiving_party": {
                    "company": this.state.pvrReceivingCompany,
                    "transport": this.state.pvrReceivingTransport,
                    "name": this.state.pvrReceivingName,
                    "signature": this.state.pvrReceivingSignature
                },
                date: moment(this.state.pvrDate).format("DD.MM.YYYY")
            };
            this.createPVReportRequest(requestObj);
        } else if (this.state.data.damageSet.name === 'Flas Aves') {
            console.log("date:" + this.state.pvrDate);
            console.log("time:" + this.state.pvrDate);
            const requestObj = {
                "items": this.state.items.map(a => a.id),
                "sending_party": {
                    "company": this.state.pvrSendingCompany,
                    "transport": this.state.pvrSendingTransport,
                    "name": this.state.pvrSendingName,
                    "signature": this.state.pvrSendingSignature
                },
                "receiving_party": {
                    "company": this.state.pvrReceivingCompany,
                    "transport": this.state.pvrReceivingTransport,
                    "name": this.state.pvrReceivingName,
                    "signature": this.state.pvrReceivingSignature
                },
                "place_of_issue": this.state.placeOfIssue,
                "date": moment(this.state.pvrDate).format("DD.MM.YYYY"),
                "time": this.state.pvrTime,
            }
            this.createPVReportRequest(requestObj);
        } else if (this.state.data.damageSet.name === 'Peugeot') {
            const requestObj = {
                "items": this.state.items.map(a => a.id),
                "date": moment(this.state.pvrDate).format("DD.MM.YYYY"),
                "location": this.state.pvrLocation,
                "transport": '',
                "delivery_note": "delivery note",
                "sending_party": {
                    "company": this.state.pvrSendingCompany,
                    "signature": this.state.pvrSendingSignature
                }
            }
            this.createPVReportRequest(requestObj);
        } else if (this.state.data.damageSet.name === 'Mazda'  || this.state.data.damageSet.name === 'Inspectus') {
            const requestObj = {
                "items": this.state.items.map(a => a.id),
                "sending_party": {
                    "company": this.state.pvrSendingCompany,
                    "transport": this.state.pvrSendingTransport,
                    "name": this.state.pvrSendingName,
                    "signature": this.state.pvrSendingSignature
                },
                "receiving_party": {
                    "company": this.state.pvrReceivingCompany,
                    "transport": this.state.pvrReceivingTransport,
                    "name": this.state.pvrReceivingName,
                    "signature": this.state.pvrReceivingSignature
                },
                "from": {
                    "factory": this.state.pvrFromFactory,
                    "road": this.state.pvrFromRoad,
                    "rail": this.state.pvrFromRail,
                    "sea": this.state.pvrFromSea,
                    "barge": this.state.pvrFromBarge,
                    "compound": this.state.pvrFromCompound,
                    "pdi": this.state.pvrFromPdi,
                    "dealer": this.state.pvrFromDealer
                },
                "to": {
                    "factory": this.state.pvrToFactory,
                    "road": this.state.pvrToRoad,
                    "rail": this.state.pvrToRail,
                    "sea": this.state.pvrToSea,
                    "barge": this.state.pvrToBarge,
                    "compound": this.state.pvrToCompound,
                    "pdi": this.state.pvrToPdi,
                    "dealer": this.state.pvrToDealer
                },
                "location": this.state.pvrLocation,
                "date": moment(this.state.pvrDate).format("DD.MM.YYYY"),
                "time": this.state.pvrTime
            }
            this.createPVReportRequest(requestObj);
        } else if (this.state.data.damageSet.name === 'AIAG') {
            const requestObj = {
                "items": this.state.items.map(a => a.id),
                "sending_party": {
                    "company": this.state.pvrSendingCompany,
                    "transport": this.state.pvrSendingTransport,
                    "name": this.state.pvrSendingName,
                    "signature": this.state.pvrSendingSignature
                },
                "receiving_party": {
                    "company": this.state.pvrReceivingCompany,
                    "transport": this.state.pvrReceivingTransport,
                    "name": this.state.pvrReceivingName,
                    "signature": this.state.pvrReceivingSignature
                },
                "date": moment(this.state.pvrDate).format("DD.MM.YYYY")
            }
            this.createPVReportRequest(requestObj);
        } else if (this.state.data.damageSet.name === 'RUA') {
            const requestObj = {
                "items": this.state.items.map(a => a.id),
                "date": moment(this.state.pvrDate).format("DD.MM.YYYY"),
                "location": this.state.pvrLocation,
                "transport": this.state.pvrSendingTransport,
                "delivery_note": this.state.pvrDeliveryNote,
                "transport_loading": this.state.pvrTransportLoading,
                "transport_unloading": this.state.pvrTransportUnloading,
                "location_factory": this.state.pvrLocationFactory,
                "location_compound": this.state.pvrLocationCompound,
                "location_dealer": this.state.pvrLocationDealer,
                "rail_control_production_factory": this.state.pvrRailControlProductionFactory,
                "rail_control_transport": this.state.pvrRailControlTransport,
                "rail_control_loading": this.state.pvrRailControlLoading,
                "rail_control_unloading": this.state.pvrRailControlUnloading,
                "sending_party": {
                    "name": this.state.pvrSendingName,
                    "signature": this.state.pvrSendingSignature,
                },
                "receiving_party": {
                    "name": this.state.pvrReceivingName,
                    "signature": this.state.pvrReceivingSignature
                }
            }
            this.createPVReportRequest(requestObj);
        }

        this.setState({
            showPVReportSettings: false
        })
    }

    renderTransportModes() {
        return this.state.availableTransportModes.map((transportMode) => {
            return (
                <>
                    {this.transportType === 'train' ?
                        <div className="col-2 col-md-2"><Form.Check
                            value={transportMode.id}
                            type="radio"
                            aria-label="radio"
                            label={transportMode.field_1}
                            onChange={this.handleTransportChange}
                            checked={this.state.selectedTransportMode === transportMode.id}
                        /> </div> : <div class="col-2 col-md-2"><Form.Check
                            value={transportMode.id}
                            type="radio"
                            aria-label="radio"
                            label={transportMode.field_1 + (transportMode.field_2 != null ? ' - ' + transportMode.field_2 : '')}
                            onChange={this.handleTransportChange}
                            checked={this.state.selectedTransportMode === transportMode.id}
                        /></div>}
                </>

            )
        })
    }

    renderItems() {
        return (
            <section id={"cargo-results"} className="bg-white mt-4 py-4">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex align-items-center mb-3">
                                <h2 className={"mb-0"}>Cargo Items: {this.state.itemCount}</h2>
                                <div className={"d-flex justify-content-end ms-auto"}>
                                    <button onClick={this.showAddTransportModal} className={"btn btn-primary"}>Add transport</button>
                                    <Dropdown>
                                        <Dropdown.Toggle className={Style.addNewUser + ' ms-2'} variant="success" id="">
                                            Import cargo items
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={this.downloadTemplate}>Download import template</Dropdown.Item>
                                            <Dropdown.Item onClick={this.importItemsModal}>Import cargo items</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <button onClick={this.importItemsDifferenceModal} className={"btn btn-danger ms-2"}>Import cargo item(s) with difference</button>
                                    <button onClick={this.importDamagesModal} className={"btn btn-primary ms-2"}>Import item damages</button>
                                    <Link to={"/dashboard/orders/order/" + this.state.id + '/add-cargo-item'} className={"btn btn-primary ms-2"}>
                                        <span className="ml-1">Add new cargo item</span>
                                    </Link>
                                </div>
                            </div>
                            <ReactPlaceholder showLoadingAnimation rows={7} ready={this.state.cargoItemsLoaded}>
                                <div className="accordion layout-grid-1">
                                    {this.state.items?.map((item) => {
                                        return (
                                            <CargoItem
                                                item={item}
                                                allUsers={this.state.allUsers}
                                                selectedCargo={this.state.selectedCargo}
                                                vin={item.vin}
                                                damages={item.damages}
                                                selectCargo={this.selectCargo}
                                                unselectCargo={this.unselectCargo}
                                                markInspected={this.markInspected}
                                                inspection={item.inspection}
                                                make={item.make}
                                                model={item.model}
                                                key={'cargo-item-' + item.id}
                                                damageSetData={this.state.damageSetData}
                                                refreshItems={this.updateOrder}
                                                inspectors={this.state.inspectorsArr}
                                                itemid={item.id}
                                                orderid={this.state.id}
                                                isOrder={true}
                                                bulkShowCargoItems={this.state.bulkShowCargoItems}
                                                transportType={this.state.transportType}
                                                transportId={this.state.orderTransportCompany}
                                                canEditOrder={this.canEditOrder()}
                                            />
                                        )
                                    })}
                                </div>
                            </ReactPlaceholder>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    renderNothingFound() {
        return (
            <section id={"cargo-results"} className="bg-white mt-4 py-4">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex align-items-center mb-3">
                                <h2 className={"mb-0"}>Cargo Items</h2>
                                <div className={"d-flex justify-content-end ms-auto"}>
                                    <button onClick={this.showAddTransportModal} className={"btn btn-primary"}>Add transport</button>
                                    <Dropdown>
                                        <Dropdown.Toggle className={Style.addNewUser} variant="success" id="">
                                            Import cargo items
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={this.downloadTemplate}>Download import template</Dropdown.Item>
                                            <Dropdown.Item onClick={this.importItemsModal}>Import cargo items</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <button onClick={this.importItemsDifferenceModal} className={"btn btn-danger ms-2"}>Import cargo item(s) with difference</button>
                                    <Link to={"/dashboard/orders/order/" + this.state.id + '/add-cargo-item'} className={"btn btn-primary ms-2"}>
                                        <span className="ml-1">Add new cargo item</span>
                                    </Link>
                                </div>
                            </div>
                            <p>No cargo items found that match the criteria</p>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    noItems(val) {
        console.log(val)
        this.setState({
            perPage: val,
            totalPages: 0,
            currentPage: 0 
        })

        this.updateOrder();
    }

    orderItems(val) {
        let order = null;
        let orderby = null;

        switch (val) {
            case "vin_asc":
                order = "asc";
                orderby = "vin";
                break;
            case "vin_desc":
                order = "desc";
                orderby = "vin";
                break;
            case "date_asc":
                order = "asc";
                orderby = "created_at";
                break;
            case "date_desc":
                order = "desc";
                orderby = "created_at";
                break;
            case "":
                order = null;
                orderby = null;
        }

        this.setState({
            order,
            orderby
        }, () => {
            this.fetchCargoItems();
        });
    }



    showAddTransportModal() {
        this.transportController.current?.addTransport(this.state.id, this.state.data.transportType);
    }

    addTransportMode(transportPlate1, transportPlate2) {
        let body = '';
        if (!Store.get('accessToken').token) {
            throw new Error('Missing auth token');
        }

        if (!transportPlate2) {
            body = {
                'field_1': transportPlate1,
                'field_2': transportPlate2,
                'order_id': this.state.id
            };
        } else {
            body = {
                'field_1': transportPlate1,
                'order_id': this.state.id
            };
        }

        return new Promise((resolve, reject) => {
            const data = body;
            Axios.post(process.env.REACT_APP_API_BASE + '/transportmode', data, {
                headers: {
                    'Authorization': 'Bearer ' + Store.get('accessToken').token
                }
            }).then(r => {
                console.log(r);
                if (r.status === 201 || r.status === 200) {
                    BulkActionsModal.fire({
                        title: 'Successfully added transport',
                        icon: 'success'
                    })

                    return resolve();
                } else {
                    return reject('Something went wrong with the api call');
                }
            })
        });
    }

    importItemsDifferenceModal(e) {
        ImportItemsModalSwal.fire({
            title: 'Import cargo items with difference',
            input: 'textarea',
            text: 'One per line!',
            showLoaderOnConfirm: true,
            showCancelButton: true,
            onBeforeOpen: () => {
                ImportItemsModalSwal.showLoading()
            },
            preConfirm: (inputValue) => {
                LoadingModalSwal.fire({
                    title: 'Please wait. Importing items...',
                    showLoaderOnConfirm: false,
                    showCancelButton: false,
                    didOpen: () => {
                        Swal.showLoading()
                    }
                });
                if (inputValue) {
                    ImportItemsModalSwal.showLoading();
                    let allItems = [];
                    const lines = inputValue.split('\n');
                    lines.forEach(line => {
                        const [vin, dest, transport_id, trailer, loading_port, transshipment_port, discharging_port, other, microlocation, voyage, additional_info, bl] = line.split('\t');
                        const lineData = {
                            vin: vin,
                            dest: dest,
                            transport_id: transport_id,
                            trailer: trailer,
                            loading_port: loading_port,
                            transshipment_port: transshipment_port,
                            discharging_port: discharging_port,
                            other: other,
                            microlocation: microlocation,
                            voyage: voyage,
                            additional_info: additional_info,
                            bl: bl
                        };
                        allItems.push(lineData);
                    })
                    this.setState({
                        importedItemsInput: allItems
                    })
                    Axios.post(process.env.REACT_APP_API_BASE + '/orders/' + this.state.id + '/items/import', allItems, {
                        headers: {
                            'Authorization': 'Bearer ' + this.state.token
                        }
                    })
                        .then(res => {
                            LoadingModalSwal.close();
                            var newArr = [];
                            var _success = res.data.success_imports;
                            var _failed = res.data.failed_imports;

                            var _newItems = res.data.new_items;
                            var _moveToUnallocated = res.data.move_to_unallocated;
                            var _updateItems = res.data.update_items;

                            if (_failed != null) {
                                newArr = _success.concat(_failed);


                                this.setState({
                                    importedItems: newArr,
                                    importedItemsInput: newArr
                                })

                                if (res.data.failed_imports.length > 0) {
                                    ImportErrorModalSwal.fire({
                                        icon: 'warning',
                                        title: 'Something went wrong!',
                                        html: renderImportItemsErrorSwalContent(res.data.failed_imports)
                                    });
                                } else {
                                    ImportItemsModalSwal.fire({
                                        icon: (res.err && res.err.length > 0) ? 'warning' : 'success',
                                        title: (res.err && res.err.length > 0) ? 'Some cargo items failed validation, either due to wrong VIN format or because they are already part of this order' : 'Successfully imported cargo items',
                                        html: renderSwalContent(res.err),
                                    });
                                    this.setState({
                                        importInProress: false
                                    })
                                }
                            } else if (_newItems != null && _moveToUnallocated != null && _updateItems != null) {

                                // newArr = _newItems.concat(_moveToUnallocated);
                                // newArr = newArr.concat(_updateItems);

                                _moveToUnallocated.forEach(item => {
                                    var newItem = {};
                                    newItem.vin = item;
                                    newItem.reason = 'Moved to unallocated'
                                    newArr.push(newItem);
                                });

                                _updateItems.forEach(item => {
                                    var newItem = {};
                                    newItem.vin = item;
                                    newItem.reason = 'Update existing item'
                                    newArr.push(newItem);
                                });

                                _newItems.forEach(item => {
                                    var newItem = {};
                                    newItem.vin = item.vin;
                                    newItem.reason = item.error ? item.error : 'Imported item';
                                    newArr.push(newItem);
                                })

                                this.setState({
                                    importedItems: newArr,
                                    previewImport: true
                                })

                                ImportItemsModalSwal.fire({
                                    icon: 'warning',
                                    title: 'Action required',
                                    html: renderSwalContent(res.err),
                                });
                            }

                            //this.state.importedItems.push(res.data.success_imports);
                            //this.state.importedItems.push(res.data.failed_imports);
                            //this.state.importedItems = res.data.success_imports.concat(res.data.failed_imports);
                            //this.state.importedItems.push.apply(res.data.success_imports, res.data.failed_imports);



                            return this.updateOrder();
                        })
                        .catch(function(error) {
                            if(error.response.status == 500) {
                                ImportErrorModalSwal.fire({
                                    icon: 'error',
                                    title: 'Something went wrong!',
                                });
                            }
                        })

                    /*return this.importItems(parseCargo(inputValue))
                        .then(async r => {
                            return {
                                moveResp: await moveCargoItems(r.success, this.state.id),
                                err: r.err
                            };
                        })
                        .then(r => {

                            ImportItemsModalSwal.fire({
                                icon: (r.err && r.err.length > 0) ? 'warning' : 'success',
                                title: (r.err && r.err.length > 0) ? 'Some cargo items failed validation, either due to wrong VIN format or because they are already part of this order' : 'Successfully imported cargo items',
                                html: renderSwalContent(r.err),
                            });
                            return this.updateOrder();
                        });*/
                }

            }
        });
    }

    downloadTemplate = () => {
        let config = {
            responseType: "blob"
        };
        config.headers = {
            'Authorization': 'Bearer ' + Store.get('accessToken').token
        };

        Axios.get(process.env.REACT_APP_API_BASE + '/downloadtemplate', config)
            .then(res => res.data)
            .then(blob => saveAs(blob, `order-${this.state.id}-item-template.xlsx`))
    }

    importItemsModal(e) {
        e.preventDefault();

        ImportItemsModalSwal.fire({
            title: 'Enter VINs',
            input: 'textarea',
            text: 'One per line!',
            showLoaderOnConfirm: true,
            showCancelButton: true,
            onBeforeOpen: () => {
                ImportItemsModalSwal.showLoading()
            },
            preConfirm: (inputValue) => {
                if (inputValue) {
                    ImportItemsModalSwal.showLoading();
                    let allItems = [];
                    const lines = inputValue.split('\n');
                    lines.forEach(line => {
                        const [vin, dest, transport_id, trailer, loading_port, loading_date, transshipment_port, discharging_port, other, microlocation, voyage, additional_info, bl] = line.split('\t');
                        const lineData = {
                            vin: vin,
                            dest: dest,
                            transport_id: transport_id,
                            trailer: trailer,
                            loading_port: loading_port,
                            loading_date: loading_date,
                            transshipment_port: transshipment_port,
                            discharging_port: discharging_port,
                            other: other,
                            microlocation: microlocation,
                            voyage: voyage,
                            additional_info: additional_info,
                            bl: bl
                        };
                        allItems.push(lineData);
                    })
                    this.setState({
                        importedItemsInput: allItems
                    })
                    this.setState({
                        importInProgress: true,
                        numberOfItems: allItems.length
                    })
                    Axios.post(process.env.REACT_APP_API_BASE + '/orders/' + this.state.id + '/items/import/normal', allItems, {
                        headers: {
                            'Authorization': 'Bearer ' + this.state.token
                        }
                    })
                        .then(res => {
                            LoadingModalSwal.close();
                            var newArr = [];
                            var _success = res.data.success_imports;
                            var _failed = res.data.failed_imports;

                            var _newItems = res.data.new_items;
                            var _moveToUnallocated = res.data.move_to_unallocated;
                            var _updateItems = res.data.update_items;

                            if (_failed != null) {
                                newArr = _success.concat(_failed);

                                this.setState({
                                    importedItems: newArr,
                                    importedItemsInput: newArr
                                })

                                if (res.data.failed_imports.length > 0) {
                                    ImportErrorModalSwal.fire({
                                        icon: 'warning',
                                        title: 'Something went wrong!',
                                        html: renderImportItemsErrorSwalContent(res.data.failed_imports)
                                    });
                                } else {
                                    ImportItemsModalSwal.fire({
                                        icon: (res.err && res.err.length > 0) ? 'warning' : 'success',
                                        title: (res.err && res.err.length > 0) ? 'Some cargo items failed validation, either due to wrong VIN format or because they are already part of this order' : 'Successfully imported cargo items',
                                        html: renderSwalContent(res.err),
                                    });
                                    this.setState({
                                        importInProgress: false
                                    })
                                }
                            } else if (_newItems != null && _moveToUnallocated != null && _updateItems != null) {

                                // newArr = _newItems.concat(_moveToUnallocated);
                                // newArr = newArr.concat(_updateItems);

                                _moveToUnallocated.forEach(item => {
                                    var newItem = {};
                                    newItem.vin = item;
                                    newItem.reason = 'Moved to unallocated'
                                    newArr.push(newItem);
                                });

                                _updateItems.forEach(item => {
                                    var newItem = {};
                                    newItem.vin = item;
                                    newItem.reason = 'Update existing item'
                                    newArr.push(newItem);
                                });

                                _newItems.forEach(item => {
                                    var newItem = {};
                                    newItem.vin = item.vin;
                                    newItem.reason = item.error ? item.error : 'Imported item';
                                    newArr.push(newItem);
                                })

                                this.setState({
                                    importedItems: newArr,
                                    previewImport: true
                                })

                                ImportItemsModalSwal.fire({
                                    icon: 'warning',
                                    title: 'Action required',
                                    html: renderSwalContent(res.err),
                                });
                            }

                            //this.state.importedItems.push(res.data.success_imports);
                            //this.state.importedItems.push(res.data.failed_imports);
                            //this.state.importedItems = res.data.success_imports.concat(res.data.failed_imports);
                            //this.state.importedItems.push.apply(res.data.success_imports, res.data.failed_imports);



                            return this.updateOrder();
                        })
                        .catch(function(error) {
                            if(error.response.status === 500) {
                                ImportErrorModalSwal.fire({
                                    icon: 'error',
                                    title: 'Something went wrong!',
                                });
                            }
                        })

                    /*return this.importItems(parseCargo(inputValue))
                        .then(async r => {
                            return {
                                moveResp: await moveCargoItems(r.success, this.state.id),
                                err: r.err
                            };
                        })
                        .then(r => {

                            ImportItemsModalSwal.fire({
                                icon: (r.err && r.err.length > 0) ? 'warning' : 'success',
                                title: (r.err && r.err.length > 0) ? 'Some cargo items failed validation, either due to wrong VIN format or because they are already part of this order' : 'Successfully imported cargo items',
                                html: renderSwalContent(r.err),
                            });
                            return this.updateOrder();
                        });*/
                }

            }
        });

    }

    importDamagesModal(e) {
        e.preventDefault();

        ImportDamagesModalSwal.fire({
            title: 'Enter damages',
            input: 'textarea',
            text: 'One per line!',
            showLoaderOnConfirm: true,
            showCancelButton: true,
            preConfirm: (inputValue) => {
                if (inputValue) {
                    let allDamages = [];
                    const lines = inputValue.split('\n');
                    lines.forEach(line => {
                        const [vin, part, type, severity, cause, repair_mode, ds_class, dln, inspectus_comment, client_comment] = line.split('\t');
                        const lineData = {
                            vin: vin,
                            part: part,
                            type: type,
                            severity: severity,
                            cause: cause,
                            repair_mode: repair_mode,
                            ds_class: ds_class,
                            dln: dln,
                            inspectus_comment: inspectus_comment,
                            client_comment: client_comment,
                        };
                        allDamages.push(lineData);
                    })
                    Axios.post(process.env.REACT_APP_API_BASE + '/damages/' + this.state.id + '/import', allDamages, {
                        headers: {
                            'Authorization': 'Bearer ' + this.state.token
                        }
                    })
                        .then(res => {
                            console.log('res damages');
                            
                            this.state.importedDamages = res.data.success_imports;
                            this.state.importedDamages = this.state.importedDamages.concat(res.data.failed_imports);
                            console.log(this.state.importedDamages);
                            ImportItemsModalSwal.fire({
                                icon: (res.err && res.err.length > 0) ? 'warning' : 'success',
                                title: (res.err && res.err.length > 0) ? 'Some cargo items failed validation, either due to wrong VIN format or because they are already part of this order' : 'Successfully imported cargo items',
                                html: renderSwalContent(res.err),
                            });
                            return this.updateOrder();
                        })

                    /*return this.importItems(parseCargo(inputValue))
                        .then(async r => {
                            return {
                                moveResp: await moveCargoItems(r.success, this.state.id),
                                err: r.err
                            };
                        })
                        .then(r => {

                            ImportItemsModalSwal.fire({
                                icon: (r.err && r.err.length > 0) ? 'warning' : 'success',
                                title: (r.err && r.err.length > 0) ? 'Some cargo items failed validation, either due to wrong VIN format or because they are already part of this order' : 'Successfully imported cargo items',
                                html: renderSwalContent(r.err),
                            });
                            return this.updateOrder();
                        });*/
                }
            }
        });

    }

    importItems(items) {
        return new Promise(async (resolve, reject) => {
            if (!items) {
                reject('Missing items parameter at importItems');
            }

            let cargoType = this.state.data.cargo_type ? this.state.data.cargo_type.toLowerCase() : null;


            validateDuplicateVin(items, this.state.id)
                .then(async r => {

                    let responses = {
                        success: [],
                        err: r.invalid || []
                    };

                    const filteredItems = r.valid;

                    for (let item of filteredItems) {
                        if (cargoType && item && vinValidation[cargoType](item.vin)) {
                            const r = await createCargoItem(item);
                            responses.success.push(r.id);
                        } else {
                            responses.err.push(item.vin);
                        }
                    }

                    resolve(responses);
                })


        });
    }

    handlePageClick(event) {
        this.fetchCargoItems(this.state.filterData, event.selected + 1);
    }

    sendReport(type) {
        if (!type) return;
        /*let config = {};*/

        /*config.headers = {
            'Authorization': 'Bearer '+Store.get('accessToken').token
        };
        Axios.post(process.env.REACT_APP_API_BASE + '/orders/'+this.state.id+'/reports/'+type,null, config)
            .then(() => {
                reportModal.fire({
                    title: 'Successfully sent ' + type + ' report',
                    icon: 'success'
                });
            }).catch(err => {
            reportModal.fire({
                title: 'Something went wrong',
                icon: 'error'
            });
        });*/

        let config = {
            responseType: "blob"
        };
        config.headers = {
            'Authorization': 'Bearer ' + Store.get('accessToken').token
        };

        Axios.post(process.env.REACT_APP_API_BASE + '/orders/' + this.state.id + '/reports/' + type, {}, config)
            .then(res => res.data)
            .then(blob => saveAs(blob, 'final-report'))
    }

    deleteOrder(e) {
        e?.preventDefault();

        orderDeleteModal.fire({
            title: 'Are you sure you want to delete?',
            showCancelButton: true,
        })
            .then(r => {
                if (r.isConfirmed) {
                    return deleteOrder(this.state.id);
                } else {
                    return false;
                }
            }).then(r => {
                if (r !== false) {
                    orderDeleteModal.fire({
                        title: 'Successfully deleted order',
                        icon: 'success'
                    }).then(() => {
                        this.props.history.push('/dashboard/orders/');
                    });
                }
            });
    }

    transportModal(transport) {
        return (
            <div className={"text-start"}>
                <div className="form-group mb-3 text-start">
                    <label htmlFor="transportsList" className={"mb-1"}>Transport</label>
                    <Select
                        closeMenuOnSelect={true}
                        options={transport}
                        value={this.state.selectedChangeTransport.value}
                        onChange={this.handleSelectChange}
                        name={"selectedChangeTransport"} />
                </div>
            </div>
        );
    }

    markInspected(item, order) {
        if (!item) return;
        this.inspectionController.current?.bulkInspect([item], this.state.id);
    }

    toggleFilters() {
        this.setState({
            filtersOpen: !this.state.filtersOpen
        })
    }

    vinChange(e) {

        let vin = e.target.value;

        if (vin.length <= 2) {
            this.setState({
                vinFilterValue: ''
            }, () => {
                this.fetchCargoItems();
            })
        } else {
            this.setState({
                vinFilterValue: vin
            }, () => {
                this.fetchCargoItems();
            })
        }
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    exportPVReport() {
        this.setState({
            showPVReportSettings: true
        })

        console.log('Clicked pv report export: Order damageset: ' + this.state.data.damageSet.name);
    }

    exportOrder() {
        var newArray = this.state.items.filter(function (el) {
            return el.make === null ||
                el.model === null
        });

        console.log(newArray);
        if(newArray.length > 0) {
            LoadingModalSwal.fire({
                title: 'Items with unknown make/model',
                text: "Are you sure you want to export?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, export'
            }).then((result) => {
                if (result.isConfirmed) {
                    console.log('confirmed');
                    let config = {
                        responseType: "blob"
                    };
                    config.headers = {
                        'Authorization': 'Bearer ' + Store.get('accessToken').token
                    };

                    let postData = {
                        items: this.state.items.map(a => a.id)
                    }

                    Axios.post(process.env.REACT_APP_API_BASE + '/orders/' + this.state.id + '/export', postData, config)
                        .then(res => res.data)
                        .then(blob => {saveAs(blob, `order-${this.state.id}-complete-export`);
                            LoadingModalSwal.close();
                        })
                }
            });
        } else {
            let config = {
                responseType: "blob"
            };
            config.headers = {
                'Authorization': 'Bearer ' + Store.get('accessToken').token
            };

            let postData = {
                items: this.state.items.map(a => a.id)
            }

            Axios.post(process.env.REACT_APP_API_BASE + '/orders/' + this.state.id + '/export', postData, config)
                .then(res => res.data)
                .then(blob => {saveAs(blob, `order-${this.state.id}-complete-export`);
                    LoadingModalSwal.close();
                })
        }

    }

    renderImportsTable() {
        if (this.state.importedItems) {
            return this.state.importedItems.map((item, index) => {
                return (
                    <tr>
                        <td>{item.vin}</td>
                        <td>{item.make ? item.make.name : ''}</td>
                        <td>{item.model ? item.model.name : ''}</td>
                        <td>{item.destination ? item.destination : ''}</td>
                        <td>{item.bl ? item.bl : ''}</td>
                        <td>{item.info ? item.info : ''}</td>
                        <td>{item.voyage ? item.voyage : ''}</td>
                        <td>{item.portOfOrigin ? item.portOfOrigin.code : ''}</td>
                        <td>{item.portOfDischarging ? item.portOfDischarging.code : ''}</td>
                        {!this.state.previewImport ? (<td>
                            {item.error != null ? (
                            <span class="badge badge-pill badge-danger bg-danger">Failed</span>
                            ) : (
                            <span class="badge badge-pill badge-danger bg-success">Success</span>
                            )
                            }
                        </td>) : (item.reason == 'Imported item' ? (item.reason == 'Invalid vin' ? <td><span class="badge badge-pill badge-danger bg-danger">Failed</span></td> : <td><span class="badge badge-pill badge-success bg-success">Success</span></td>) : <td><span class="badge badge-pill badge-warning bg-warning">Warning</span></td>)}

                        {!this.state.previewImport ? (<td>
                            {item.error != null ? (
                            item.error
                            ) : (
                            ''
                            )
                            }
                        </td>) : (<td>{item.reason}</td>)}
                    
                    </tr>
                );
            });
        }
    }

    renderDamageItemsTable() {
        if (this.state.importedDamages) {
            return this.state.importedDamages.map((item, index) => {
                return (
                    <tr>
                        <td>{item.vin}</td>
                        <td>{item.part}</td>
                        <td>{item.type}</td>
                        <td>{item.severity}</td>
                        <td>{item.cause}</td>
                        <td>{item.repair_mode}</td>
                        <td>{item.class}</td>
                        <td>{item.dln}</td>
                        <td>{item.client_comment}</td>
                        <td>{item.inspectus_comment}</td>
                        <td>
                            {item.reason != null ? (
                            <span class="badge badge-pill badge-danger bg-danger">Failed</span>
                            ) : (
                            <span class="badge badge-pill badge-danger bg-success">Success</span>
                            )
                            }
                        </td>

                        {!this.state.previewImport ? (<td>
                            {item.reason != null ? (
                            item.reason
                            ) : (
                            ''
                            )
                            }
                        </td>) : (<td>{item.reason}</td>)}
                    
                    </tr>
                );
            });
        }
    }

    renderPreviewTable() {
        if (this.state.importedItems) {
            return this.state.importedItems.map((item, index) => {
                return (
                    <tr>
                        <td>{item.vin}</td>
                        {item.error != null ? (
                            <td><span class="badge badge-pill badge-danger bg-danger">Failed</span></td>
                        ) : (
                            <td><span class="badge badge-pill badge-danger bg-success">Success</span></td>
                        )
                        }
                        {item.error != null ? (<td>{item.error}</td>) : (<td></td>)}
                    </tr>
                );
            });
        }
    }

    setPVRdate(date) {
        this.setState({
            pvrDate: date 
        })
    }

    renderImportTableFooter() {
        return (
            <div>
            <button className="btn btn-primary m-1" onClick={this.confirmImport}>Accept</button>
            <button className="btn btn-danger">Cancel</button>
            </div>
        )
    }

    confirmImport() {
        LoadingModalSwal.fire({
            title: 'Please wait. Importing items...',
            showLoaderOnConfirm: false,
            showCancelButton: false,
            didOpen: () => {
                Swal.showLoading()
            }
        });
        Axios.post(process.env.REACT_APP_API_BASE + '/orders/' + this.state.id + '/items/import?confirm=true', this.state.importedItemsInput, {
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            }
        }).then(res => {
            LoadingModalSwal.close();
            console.log(res);
            this.setState({
                importedItems: []
            }, () => {
                this.updateOrder();
            });
        })
    }

    neptuneExport() {
        let config = {
            responseType: "blob"
        };
        config.headers = {
            'Authorization': 'Bearer ' + Store.get('accessToken').token
        };

        Axios.get(process.env.REACT_APP_API_BASE + '/orders/' + this.state.id + '/neptune', config)
            .then(res => res.data)
            .then(blob => saveAs(blob, `neptune`))
    }

    cloneOrder() {
        cloneOrderModal.fire({
            title: "Do you really want to clone order?",
            showCancelButton: true,
            confirmButtonText: "Save",
            denyButtonText: `Don't save`
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                Axios.post(process.env.REACT_APP_API_BASE + '/orders/' + this.state.id + '/clone', '', {
                    headers: {
                        'Authorization': 'Bearer ' + this.state.token
                    }
                })
                .then(res => {
                    if(res.status === 200) {
                        this.props.history.push('/dashboard/orders/order/' + res.data.id);
                    }
                })
            } else if (result.isDenied) {
              Swal.fire("Changes are not saved", "", "info");
            }
          });

    }

    rescanItems() {
        Axios.post(process.env.REACT_APP_API_BASE + '/orders/' + this.state.id + '/items/rescan', {items: this.state.selectedCargo}, {
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            }
        })
        .then(res => {
            if(res.status === 200) {
                ImportItemsModalSwal.fire({
                    icon: 'success',
                    title: 'Items scanned'
                });
            }
        })
    }

    render() {
        return (
            <div className="" id="main">
                <InspectionController
                    ref={this.inspectionController}
                    cargoItems={this.state.selectedCargo}
                    fetchCargoData={this.updateOrder}
                    inspectors={this.state.inspectorsArr}
                />
                <TransportController
                    ref={this.transportController}
                    fetchCargoData={this.fetchTransportModes}
                />

                <PVReportController
                    ref={this.pvReportController}
                />

                <div className="container">
                    <div className="row mb-5">
                        <div className="col-12 col-md-4">
                            <Link to={"/dashboard/orders/"} className={"btn-min"}>
                                <FontAwesomeIcon icon={faChevronLeft} />
                                <span className="ml-1">View all Orders</span>
                            </Link>
                            <h1 className={"my-0"}>Order: {this.state.data ? this.state.data.idNumber : null}</h1>
                        </div>
                        <div className="col-12 col-md-8">
                            <div className="d-flex justify-content-end">
                                <button className={"btn btn-primary me-2"} onClick={this.rescanItems}>Rescan items</button>
                                <button className={"btn btn-primary me-2"} onClick={this.neptuneExport}>Neptune report</button>
                                <button className={"btn btn-primary me-2"} onClick={this.exportOrder}>Export order</button>
                                <button className={"btn btn-primary me-2"} onClick={this.exportPVReport}>Export PV report</button>
                                <button className={"btn btn-primary me-2"} onClick={this.cloneOrder}>Clone order</button>
                                <button className={"btn btn-primary me-2"} onClick={this.deleteOrder}>Delete order</button>
                                <ConditionalContent check={this.canEditOrder()}>
                                    <Link to={"/dashboard/orders/edit/" + this.state.id} className={"btn btn-primary me-2"}>
                                        <span className="ml-1">Edit order details</span>
                                    </Link>
                                </ConditionalContent>
                                <ConditionalContent check={isAdmin()}>
                                    <Link to={"/dashboard/orders/add-new"} className={"btn btn-secondary"}>
                                        <span className="ml-1">Add new order</span>
                                    </Link>
                                </ConditionalContent>
                                <ConditionalContent check={isClient()}>
                                    <Link to={"/dashboard/orders/client-add-new"} className={"btn btn-secondary"}>
                                        <span className="ml-1">Add new order</span>
                                    </Link>
                                </ConditionalContent>
                                <ConditionalContent check={isManager()}>
                                    <Link to={"/dashboard/orders/manager-add-new"} className={"btn btn-secondary"}>
                                        <span className="ml-1">Add new order</span>
                                    </Link>
                                </ConditionalContent>
                            </div>
                            <ConditionalContent check={isAdmin()} className={"d-flex flex-column justify-content-end align-items-end mt-3"}>
                                <button className="btn-min" onClick={() => { this.props.history.push('/dashboard/orders/' + this.state.id + '/preliminaryreport'); }}>Send Preliminary Report</button>
                                <button className="btn-min" onClick={() => { this.props.history.push('/dashboard/orders/' + this.state.id + '/finalreport'); }}>Send final Report</button>
                            </ConditionalContent>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card no-shadow">
                                <div className="card-body pb-5">
                                    <ReactPlaceholder showLoadingAnimation rows={7} ready={this.state.itemsLoaded}>
                                        <div className="row">
                                            <div className="col-12">
                                                <strong className={"d-block mb-4"}>Order Summary and Details</strong>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-md-3 metas">
                                                <div className="data-item mb-3">
                                                    <span className="label">Cargo Items</span>
                                                    <span className="count">{this.state.itemCount || '/'}</span>
                                                </div>
                                                <div className="data-item mb-3">
                                                    <span className="label">Cargo Items with remarks ({this.state.itemsWithComments})</span>
                                                    <span className="count">{this.state.itemsWithComments && this.state.itemCount ? Math.round((this.state.itemsWithComments / this.state.itemCount) * 100) + '%' : '/'}</span>
                                                </div>
                                                <div className="data-item mb-3">
                                                    <span className="label">Number of remarks</span>
                                                    <span className="count">{this.state.numberOfDamages || '/'}</span>
                                                </div>
                                                <div className="data-item mb-3">
                                                    <span className="label">Transport remarks</span>
                                                    <span className="count">{this.state.data?.damaged_in_transport_count}</span>
                                                </div>
                                                <div className="data-item">
                                                    <span className="label">Claimable cargo items</span>
                                                    <span className="count">{this.state.data?.claimable_count}</span>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-9">
                                                <div className="row">
                                                    <div className="col-12 col-md-4">
                                                        <strong className={"text-uppercase d-block"}>Basic info</strong>
                                                        <div>
                                                            <div className="data-item">
                                                                <strong>Client: </strong>
                                                                {this.state.data && this.state.data.client ? <span>{this.state.data.client['company_name']}</span> : '/'}
                                                            </div>
                                                            <div className="data-item">
                                                                <strong>Cargo Type: </strong>
                                                                {this.state.data && this.state.data.cargo_type ? <span>{this.state.data.cargo_type}</span> : '/'}
                                                            </div>
                                                            <div className="data-item">
                                                                <strong>Damage Set: </strong>
                                                                {this.state.data && this.state.data.damageSet ? <span>{this.state.data.damageSet.name}</span> : '/'}
                                                            </div>
                                                            <div className="data-item">
                                                                <strong>Order Manager: </strong>
                                                                {this.state.data && this.state.data.admin ? <span>{this.state.data.admin['name']}</span> : '/'}
                                                            </div>
                                                            <div className="data-item">
                                                                <strong>Inspectors: </strong>
                                                                {this.state.inspectorNames ? <span>{this.state.inspectorNames.join(', ')}</span> : <span>/</span>}
                                                            </div>
                                                            <div className="data-item">
                                                                <strong>Transport Type: </strong>
                                                                {this.state.data && this.state.data.transportType ? <span>{this.state.data.transportType}</span> : '/'}
                                                            </div>
                                                            <div className="data-item">
                                                                <strong>Gate: </strong>
                                                                {this.state.data && this.state.data.gate ? <span>{this.state.data.gate}</span> : '/'}
                                                            </div>
                                                            <div className="data-item">
                                                                <strong>Inspection Type: </strong>
                                                                {this.state.data && this.state.data.inspectionType ? <span>{this.state.data.inspectionType}</span> : '/'}
                                                            </div>
                                                            <div className="data-item">
                                                                <strong>Transport ID's: </strong>
                                                                {this.state.transportIds ? <span>{this.state.transportIds.join(', ')}</span> : <span>/</span>}
                                                            </div>
                                                            <div className="data-item">
                                                                <strong>Created at: </strong>
                                                                {this.state.data ? <span>{moment(this.state.data.createdOn).format('DD.MM.YYYY HH:mm')}</span> : <span>/</span>}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-md-3">
                                                        <strong className="text-uppercase d-block">Order notes</strong>
                                                        {this.state.data ? this.state.data["order_notes"] : null}
                                                    </div>
                                                    <div className="col-12 col-md-5">
                                                        <strong className="text-uppercase d-block">Order general images</strong>
                                                        <Gallery type={"order"} images={this.state.images} />
                                                        <ConditionalContent check={this.state.images && this.state.images.length > 0}>
                                                            <button className={"btn-min mt-2"} onClick={this.downloadPackage}>Download All Images</button>
                                                        </ConditionalContent>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ReactPlaceholder>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12">
                            <section>
                                <Accordion>
                                    <Card className={"no-shadow"}>
                                        <Accordion.Toggle as={Card.Header} eventKey="0" onClick={this.toggleFilters}>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <span>Filter Data</span>
                                                <FontAwesomeIcon icon={this.state.filtersOpen ? faChevronUp : faChevronDown} />
                                            </div>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0">
                                            <Card.Body>
                                                {this.state.filtersOpen ? <Filters
                                                    updateItems={this.fetchCargoItems}
                                                    handleScrollToElement={this.handleScrollToElement}
                                                    orderId={this.state.id}
                                                    countries={this.state.countries}
                                                    transports={this.state.transports}
                                                    bls={this.state.bls}
                                                    portsOfDischarging={this.state.portsOfDischarging}
                                                    transshipmentPorts={this.state.transshipmentPorts}
                                                    portsOfOrigin={this.state.portsOfOrigin}
                                                    voyages={this.state.voyages}
                                                    makes={this.state.makes}
                                                    models={this.state.models}
                                                    items={this.state.items}
                                                    inspectors={this.state.inspectorsArr}
                                                    inspectusComments={this.state.inspectusComments}
                                                    transportCompanies={this.state.transportCompanies}
                                                    damageSetData={this.state.damageSetData}
                                                    transportModes={this.state.availableTransportModes}
                                                /> : ''}
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </section>
                        </div>
                        {this.state.importInProgress ?
                            <div className="col-12">
                                <section>
                                    <Card className={"no-shadow"}>
                                        <Card.Body>
                                            <h2>Import Progress</h2>
                                            Importing item {this.state.importedItemsCounter} of {this.state.numberOfItems}
                                            <div className={`${OrderStyle.progressBar}`} style={{'width': this.state.importedPercentage + '%'}}></div>
                                        </Card.Body>
                                    </Card>
                                </section>
                            </div> : ''}
                    </div>
                    <div className="row mt-3">
                        <div className="col-12">
                        {this.state.importedDamages.length > 0 ?<section>
                                <Accordion>
                                    <Card className={"no-shadow"}>
                                        <Card.Header>
                                            Import item damages detailed info
                                        </Card.Header>
                                        <Card.Body>
                                            <div class="table-fixed-height">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Vin</th>
                                                            <th scope="col">Part</th>
                                                            <th scope="col">Type</th>
                                                            <th scope="col">Severity</th>
                                                            <th scope="col">Cause</th>
                                                            <th scope="col">Repair Mode</th>
                                                            <th scope="col">Class</th>
                                                            <th scope="col">Dln</th>
                                                            <th scope="col">Comment client</th>
                                                            <th scope="col">Comment inspectus</th>
                                                            <th scope="col">Status</th>
                                                            <th scope="col">Reason</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.renderDamageItemsTable()}

                                                    </tbody>
                                                </table>
                                            </div>    

                                        </Card.Body>
                                    </Card>
                                </Accordion>
                            </section> : ''}
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12">
                            {this.state.showPVReportSettings ? <section>
                                <Accordion>
                                    <Card className={"no-shadow"}>
                                        <Card.Header>
                                            PV Report Settings 
                                        </Card.Header>
                                        <Card.Body>
                                            {this.renderPVReportSettings()}

                                        </Card.Body>
                                    </Card>
                                </Accordion>
                            </section> : ''}
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12">
                            {this.state.importedItems.length > 0 ? <section>
                                <Accordion>
                                    <Card className={"no-shadow"}>
                                        <Card.Header>
                                            Import data detailed info
                                        </Card.Header>
                                        <Card.Body>
                                            <div class="table-fixed-height">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Vin</th>
                                                            <th scope="col">Make</th>
                                                            <th scope="col">Model</th>
                                                            <th scope="col">Destination</th>
                                                            <th scope="col">BL</th>
                                                            <th scope="col">Info</th>
                                                            <th scope="col">Voyage</th>
                                                            <th scope="col">Port of origin</th>
                                                            <th scope="col">Port of discharging</th>
                                                            <th scope="col">Status</th>
                                                            <th scope="col">Reason</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.renderImportsTable()}

                                                    </tbody>
                                                </table>
                                            </div>
                                            {this.state.previewImport ? (this.renderImportTableFooter()) : ('')}
                                            

                                        </Card.Body>
                                    </Card>
                                </Accordion>
                            </section> : ''}
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12">
                            <section>
                                <Card className="no-shadow">
                                    <Card.Body>
                                        <HelperControls
                                            orderItems={this.orderItems}
                                            selectAllCargo={this.selectAllCargo}
                                            unselectAllCargo={this.unselectAllCargo}
                                            bulkActionControl={this.bulkActionControl}
                                            selectAllChecked={this.state.selectAllChecked}
                                            noItems={this.noItems}
                                            damageSetData={this.state.damageSetData}
                                        />
                                    </Card.Body>
                                </Card>
                            </section>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12">
                            <section>
                                <Card className="no-shadow">
                                    <Card.Body>
                                        <strong className={"d-block mb-3"}>Search by VIN</strong>
                                        <input type="text" placeholder="Enter vin" className="vinSearch form-control w-25" itemID="vinSearch" onChange={this.vinChange} disabled={this.state.vinSearchInputIsDisabled}></input>
                                    </Card.Body>
                                </Card>
                            </section>
                        </div>
                    </div>
                </div>
                <div>
                    {this.state.items && this.state.items.length > 0 ? this.renderItems() : this.renderNothingFound()}
                </div>
                <ConditionalContent check={this.state.totalPages > 1}>
                    <div>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel="next >"
                                        onPageChange={this.handlePageClick}
                                        pageRangeDisplayed={2}
                                        pageCount={this.state.totalPages}
                                        previousLabel="< previous"
                                        renderOnZeroPageCount={false}
                                        forcePage={this.state.currentPage - 1}
                                        containerClassName={'pagination-wrapper'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </ConditionalContent>

                <div className="container">
                    <div className="row mt-3">
                        <div className="col-12">
                            <section>
                                <Card className="no-shadow">
                                    <Card.Body>
                                        <HelperControls
                                            orderItems={this.orderItems}
                                            selectAllCargo={this.selectAllCargo}
                                            unselectAllCargo={this.unselectAllCargo}
                                            bulkActionControl={this.bulkActionControl}
                                            selectAllChecked={this.state.selectAllChecked}
                                            noItems={this.noItems}
                                            damageSetData={this.state.damageSetData}
                                        />
                                    </Card.Body>
                                </Card>
                            </section>
                        </div>
                    </div>
                </div>



            </div>
        );
    }
}

SingleOrder.propTypes = {};

export default SingleOrder;
