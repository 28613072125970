import React from "react";
import {Link} from "react-router-dom";
import Style from "./Mapping.module.scss";

class Mapping extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <Link className={Style.item + ' card no-shadow'} to={"/dashboard/mapping/edit/"+this.props.entry.id}>
                <div className="card-body bg-white py-3">
                    <span className={"d-block strong"}>Name: {this.props.entry.company.name}</span>
                </div>
            </Link>
        );
    }
}

Mapping.propTypes = {};

export default Mapping;
