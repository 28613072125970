import React from "react";
import { SRLWrapper } from "simple-react-lightbox";
import Style from "../Damage.module.scss";
import Image from "./Image/Image";
import ConditionalContent from "../../../../../../../../ConditionalContent";

class DamageImages extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            images: this.props.images,
            files: [],
            setFiles: []
        };

        this.handleDragStart = this.handleDragStart.bind(this);
        this.handleDragOver = this.handleDragOver.bind(this);
        this.handleDrop = this.handleDrop.bind(this);

    }


    static getDerivedStateFromProps(props, current_state) {
        if (current_state.images !== props.images) {
            return {
                images: props.images
            }
        }
        return null
    }

    handleDragStart = (event) => {
        event.dataTransfer.setData("text/plain", event.target.id)
    }

    handleDragOver = (event) => {
        event.preventDefault()
    }

    handleDrop = (event) => {
        event.preventDefault();
        const { files } = event.dataTransfer;
        if (files.length > 0) {
            console.log(files);
            this.props.directDragAndDropImageUpload(files);
        }
    };

    render() {

        const srlOptions = {
            buttons: {
                showAutoplayButton: false,
                showCloseButton: false,
                showDownloadButton: false,
                showFullscreenButton: false,
                showNextButton: false,
                showPrevButton: false,
                showThumbnailsButton: false,
            }
        }

        if (this.state.images && this.state.images.length > 0) {
            return (
                <div className={Style.dmgWrap}>
                    <SRLWrapper options={srlOptions}>
                        {
                            this.state.images.map(image => {
                                return (
                                    <Image {...this.props} key={'img-' + this.props.dmgId + '-' + image} imgId={image} />
                                )
                            })
                        }
                        <ConditionalContent check={this.props.allowUpload}>
                            <div>
                                <div>
                                    <div className={"aresDragAndDrop"}>
                                        <div>
                                            <div className="" onDragOver={this.handleDragOver} onDrop={this.handleDrop} >
                                                <div className="" style={{
                                                    display: "swal2-file"
                                                }} draggable="true" onDragStart={this.handleDragStart}>
                                                    <div className='file-upload-div'>
                                                        <ul style={{
                                                            paddingLeft: "0px"
                                                        }}>
                                                            ... drop files here
                                                            {this.state.files.map((file, index) => (
                                                                <li key={index}>{file.name}</li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </ConditionalContent>
                    </SRLWrapper>

                </div>

            );
        } else {
            if (this.props.allowUpload) {
                return (
                    <div>
                        <div>
                            <div className={"aresDragAndDrop"}>
                                <div>
                                    <div className="" onDragOver={this.handleDragOver} onDrop={this.handleDrop} >
                                        <div className="" style={{
                                            display: "swal2-file"
                                        }} draggable="true" onDragStart={this.handleDragStart}>
                                            <div className='file-upload-div'>
                                                <ul style={{
                                                    paddingLeft: "0px"
                                                }}>
                                                    ... drop files here
                                                    {this.state.files.map((file, index) => (
                                                        <li key={index}>{file.name}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                );
            } else {
                return null;
            }
        }

    }
}

DamageImages.propTypes = {};

export default DamageImages;
