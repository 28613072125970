import React from "react";
import {
    Link,
    withRouter
} from "react-router-dom";
import Store from "store";
import Axios from "axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faTimes} from "@fortawesome/free-solid-svg-icons";
import {getCurrentUser} from "../../../../utils/utils";
import Style from '../../Managers/EditManager/EditManager.module.scss';


class EditAdmin extends React.Component {
    constructor(props) {
        super(props);


        this.state = {
            id: this.props.match.params.entryId,
            token: Store.get('accessToken').token,
            name: '',
            email: '',
            password1: '',
            password2: '',
            signature: '',
            file: ''
        };

        if (this.state.token && this.state.id) {
            Axios.get(process.env.REACT_APP_API_BASE + '/admins/'+this.state.id, {
                headers: {
                    'Authorization': 'Bearer ' + this.state.token
                },
            } ).then(response => {
                if (response.status === 200) {
                    this.setState({
                        name: response.data.admin.name,
                        email: response.data.admin.email,
                        signature: response.data.admin.signature
                    });
                }
            }).catch(error => {
                console.log('error', error);
            });
        }


        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.doUpdateUser = this.doUpdateUser.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.handleFileInput = this.handleFileInput.bind(this);
        this.deleteSignature = this.deleteSignature.bind(this);
    }

    handleFileInput(e) {
        console.log('FILES');
        console.log(e.target.files[0]);
        this.setState({
            file: e.target.files[0]
        })
    }

    handleSubmit(e) {
        e.preventDefault();
        if (!this.state.email || !this.state.name) {
            this.setState({
                'formErrors': 'Missing fields'
            });

            return null;
        }
        else if (this.state.password1 !== this.state.password2) {
            this.setState({
                'formErrors': 'Passwords don\'t match'
            });

            return null;
        }
        else {
            this.setState({
                'formErrors': ''
            });
        }

        this.doUpdateUser();
    }

    //Performs user update
    doUpdateUser() {
        console.log('UPLOADED FILED');
        console.log(this.state.file);
        var bodyFormData = new FormData();
        bodyFormData.append('name', this.state.name);
        bodyFormData.append('email', this.state.email);
        bodyFormData.append('signature', this.state.file);
    

        if (this.state.password1 && this.state.password2) {
            bodyFormData.password = this.state.password1;
        }

        Axios.post(process.env.REACT_APP_API_BASE + '/admins/'+this.state.id + '?_method=PUT', bodyFormData, {
            headers: {
                'Authorization': 'Bearer ' + this.state.token,
                "Content-Type": "multipart/form-data"
            }
        } )
            .then(response => {
                console.log('test resp', response);
                if (response.status === 200) {
                    if (this.state.id === Store.get('user').id) {
                        getCurrentUser(this.state.id).then(() => {
                            this.props.userUpdate(this.state.id);
                            this.props.history.push('/dashboard/users');
                        });
                    } else {
                        this.props.history.push('/dashboard/users');
                    }

                }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    'formErrors': error.response.data.message
                })
            })
    }

    deleteSignature() {
        Axios.delete(process.env.REACT_APP_API_BASE + '/signature/' + this.state.id, {
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            }
        }).then(res => {
            if(res.status === 200) {
                this.setState({
                    signature: ''
                })
            }
        })
    }

    handleChange(e) {
        //Sets state with the appropriate key, to the matching input's value
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    deleteUser(e) {
        e.preventDefault();
        Axios.delete(process.env.REACT_APP_API_BASE + '/admins/' + this.state.id, {
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            }
        }).then(res => {
            if(res.status === 200) {
                return this.props.history.push('/dashboard/users');
            }
        })
    }

    render() {

        return (
            <div className="container" id="main">
                <div className="row mb-5">
                    <div className="col-12 col-md-8">
                        <Link to={"/dashboard/admins/"} className={"btn-min"}>
                            <FontAwesomeIcon icon={faChevronLeft} />
                            <span className="ml-1">View all Admins</span>
                        </Link>
                        <h1 className={"mt-1"}>Editing Admin: {this.state.name ? this.state.name : null}</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-7">
                        <div className=" no-shadow no-border">
                            <div className="row">
                                <div className="col-12">
                                    <form action="" className={"mb-5"} onSubmit={this.handleSubmit}>
                                        <div className="form-group">
                                            <label htmlFor="email">Email</label>
                                            <input type="email" className={"form-control"} name={"email"} id={"email"} value={this.state.email} />
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label htmlFor="name">Name*</label>
                                                    <input type="text" placeholder={"John"} className={"form-control"} name={"name"} id={"name"} value={this.state.name} onChange={this.handleChange} required />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row mt-3">
                                            <div className="col-12 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="password1">Password</label>
                                                    <input type="password" className={"form-control"} name={"password1"} id={"password1"} value={this.state.password1} onChange={this.handleChange} />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="password2">Password Confirmation</label>
                                                    <input type="password" className={"form-control"} name={"password2"} id={"password2"} value={this.state.password2} onChange={this.handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                        {(this.state.signature) ?
                                            <div className="mt-3 d-flex flex-column position-relative">
                                                <FontAwesomeIcon icon="fa-solid fa-xmark" />
                                                <label htmlFor="password2">Current signature</label>
                                                <img className={Style.signature} src={this.state.signature}></img>
                                                <FontAwesomeIcon icon={faTimes} onClick={this.deleteSignature} className={Style.deleteSignature} />
                                            </div>
                                        : ''}
                                        <div className="row mt-3">
                                            <div className="col-12">
                                                <div className="file-uploader">
                                                    <label htmlFor="formFileMultiple" className="form-label">Signature</label>
                                                    <input className="form-control" type="file" id="formFileMultiple" onChange={this.handleFileInput} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="errors">
                                                    <h6 className={"mb-0"}>{this.state.formErrors}</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-12">
                                                <button type={"submit"} className={"btn btn-primary"}>Submit</button>
                                                <button onClick={this.deleteUser} className={"btn btn-danger ms-2"}>Delete</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

EditAdmin.propTypes = {};

export default withRouter(EditAdmin);
