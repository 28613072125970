import React from "react";
import {displayDate, getUser} from "../../../../../../utils/utils";

import Style from "../Damaged.module.scss";
import DamageImages from "../../../../Orders/SingleOrder/CargoItem/DamagesWrapper/Damage/DamageImages/DamageImages";
import SimpleReactLightbox from "simple-react-lightbox";

class DamageDetails extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        };

    }


    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-12 col-md-4">
                        <table className={Style.damageInnerData}>
                            <tr>
                                <th>DS Cause</th>
                                <td>{this.props.data?.ds_cause ? `${this.props.data.ds_cause.code} - ${this.props.data.ds_cause.name}` : '/' }</td>
                            </tr>
                            <tr>
                                <th>DS Class</th>
                                <td>{this.props.data?.ds_class ? `${this.props.data.ds_class.code} - ${this.props.data.ds_class.name}` : '/' }</td>
                            </tr>
                            <tr>
                                <th>DS DLN</th>
                                <td>{this.props.data?.ds_dln ? `${this.props.data.ds_dln.code} - ${this.props.data.ds_dln.name}` : '/' }</td>
                            </tr>
                            <tr>
                                <th>DS Part</th>
                                <td>{this.props.data?.ds_part ? `${this.props.data.ds_part.code} - ${this.props.data.ds_part.name}` : '/' }</td>
                            </tr>
                            <tr>
                                <th>DS Repair Mode</th>
                                <td>{this.props.data?.ds_repair_mode ? `${this.props.data.ds_repair_mode.code} - ${this.props.data.ds_repair_mode.name}` : '/' }</td>
                            </tr>
                            <tr>
                                <th>DS Severity</th>
                                <td>{this.props.data?.ds_severity ? `${this.props.data.ds_severity.code} - ${this.props.data.ds_severity.name}` : '/' }</td>
                            </tr>
                            <tr>
                                <th>DS Type</th>
                                <td>{this.props.data?.ds_type ? `${this.props.data.ds_type.code} - ${this.props.data.ds_type.name}` : '/' }</td>
                            </tr>
                        </table>
                    </div>
                    <div className="col-12 col-md-4">
                        <table className={Style.damageInnerData}>
                            <tr>
                                <th>Claimable</th>
                                <td>{this.props.data?.claimable ? 'Yes' : 'No'}</td>
                            </tr>
                            <tr>
                                <th>Damaged in Transport</th>
                                <td>{this.props.data?.damaged_in_transport ? 'Yes' : 'No'}</td>
                            </tr>
                            <tr>
                                <th>Repaired</th>
                                <td>{this.props.data?.repaired ? 'Yes' : 'No'}</td>
                            </tr>
                            <tr>
                                <th>Repaired at</th>
                                <td>{this.props.data?.repaired_date_time ? displayDate(this.props.data.repaired_date_time) : '/'}</td>
                            </tr>
                            <tr>
                                <th>Client Comment</th>
                                <td>{this.props.data?.client_comment ? this.props.data?.client_comment : '/'}</td>
                            </tr>
                            <tr>
                                <th>Inspectus Comment</th>
                                <td>{this.props.data?.inspectus_comment ? this.props.data?.inspectus_comment : '/'}</td>
                            </tr>
                        </table>
                    </div>

                    <div className="col-12 mt-3">
                        {this.props.data?.images ?
                            <SimpleReactLightbox>
                                <DamageImages
                                    allowUpload={false}
                                    dmgId={this.props.data.id}
                                    images={this.props.data.images}
                                />
                            </SimpleReactLightbox>

                            : null}
                    </div>
                </div>
            </div>
        );
    }
}

DamageDetails.propTypes = {};

export default DamageDetails;
