import React from "react";
import {Link, withRouter} from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import countries from "../../../../fixedData/countries.json";

import Axios from "axios";
import Store from "store";

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const SuccessModal = withReactContent(Swal);




class AddNewPort extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            token: Store.get('accessToken').token,
            code: null,
            name: null,
            description: null,
            type: null,
            destinationCountry: null
        };


        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        console.log(countries);
    }

    handleSubmit(e) {
        e.preventDefault();

        let formData = {
            code: this.state.code,
            name: this.state.name,
            description: this.state.description,
            type: this.state.type,
            destinationCountry: this.state.destinationCountry
        };

        Axios.post(process.env.REACT_APP_API_BASE + '/ports', formData, {
            headers: {
                'Authorization': 'Bearer ' + this.state.token
            }
        } )
            .then(response => {
                console.log(response);
                if (response.status === 201) {
                    SuccessModal.fire({
                        title: 'Successfully added port',
                    }).then(() => {
                        this.props.history.push('/dashboard/ports');
                    });
                }
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    'formErrors': error.response.data.message
                })
            })

    }

    handleChange(e) {

        //Sets state with the appropriate key, to the matching input's value
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    render() {

        return (
            <div className="container" id="main">
                <div className="row mb-5">
                    <div className="col-12 col-md-8">
                        <Link to={"/dashboard/ports/"} className={"btn-min"}>
                            <FontAwesomeIcon icon={faChevronLeft} />
                            <span className="ml-1">View all Ports</span>
                        </Link>
                        <h1 className={"mt-1"}>Add new Port</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-7">
                        <div className=" no-shadow no-border">
                            <div className="row">
                                <div className="col-12">
                                    <form action="" className={"mb-5"} onSubmit={this.handleSubmit}>

                                        <section className={"mb-5"}>
                                            <div className="row mb-3">
                                                <div className="col-12 col-md-6">
                                                    <label htmlFor="code">Code</label>
                                                    <input type="text" className={"form-control"} name={"code"} id={"code"} onChange={this.handleChange} value={this.state.code} />
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <label htmlFor="name">Name</label>
                                                    <input type="text" className={"form-control"} name={"name"} id={"name"} onChange={this.handleChange} value={this.state.name} />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-12 col-md-6">
                                                    <label htmlFor="type">Type</label>
                                                    <select id={"type"}  onChange={this.handleChange} value={this.state.type} className={"form-select"} name="type">
                                                        <option value="0">Sea Port</option>
                                                        <option value="1">Inland</option>
                                                        <option value="2">Country</option>
                                                        <option value="3">Hub</option>
                                                    </select>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <label htmlFor="city">Country (missing from api)</label>
                                                    <input type="text" className={"form-control"} name={"city"} id={"city"} onChange={this.handleChange} value={this.state.city} />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-12">
                                                    <label htmlFor="description">Description</label>
                                                    <textarea className={"form-control"} name={"description"} id={"description"} onChange={this.handleChange} value={this.state.description} />
                                                </div>
                                            </div>
                                        </section>

                                        <div className="row mt-3">
                                            <div className="col-12">
                                                <button type={"submit"} className={"btn btn-primary"}>Submit</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

AddNewPort.propTypes = {};

export default withRouter(AddNewPort);
